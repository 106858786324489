import React, { useState } from 'react'
import styled from 'styled-components';
import Select from 'react-select';
import { levelsList, levelAggregation } from '../../Utils/staticData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Modal } from "react-bootstrap";
import BasicTable from '../common/BasicTable';
import { columnsLevelAggregation } from '../common/TableColumns';

function SectionC() {

  const [levelList, setLevelList] = useState(levelsList);
  const [levelSelected, setLevelSelected] = useState(null);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleChangeLevel = (selected) => {
    const { value } = selected;
    setLevelSelected(selected);
    
  }

  const LevelPopupModal = () => {
    return (
      <Modal show={show} onHide={handleClose} dialogClassName={'my-modal'}>
        <Modal.Header closeButton>
          <Modal.Title>Level of aggregation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BasicTable inputData={levelAggregation} columnNames={columnsLevelAggregation} />
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    )
  }

  const RenderLevelControlSection = (levelName) => {
    switch (levelName) {
      case 'level1':
      case 'level2':
      case 'level3': return (<h1>Group A</h1>);

      case 'level5':
      case 'level6':
      case 'level7':
      case 'level8':
      case 'level9': return (<h1>Group B</h1>);
      case 'level10':
      case 'level11':
      case 'level12': return (<h1>Group C</h1>);
      default: break;
    }
  }

  return (
    <Wrapper>
      <div className="control-section div-aggr-level"  >

        <span className='faicon-style' >
          <label className='mandatory lbl-for-tb' >Aggregate Level</label>
          <i onClick={handleShow} className='fa-lg fa-sharp fa-light fa1' > <FontAwesomeIcon icon={faCircleExclamation} /></i>
        </span>
        <Select
          key="level"
          options={levelList}
          onChange={handleChangeLevel}
          value={levelSelected}
          className=""
        />
      </div>
      {
        RenderLevelControlSection(levelSelected?.value?.toLowerCase())
      }
      {/* <div id="divGroupA" className="control-section div-grp-a"  >
        <h1>Group-A</h1>
      </div>
      <div id="divGroupB" className="control-section div-grp-b"  >
        <h1>Group-A</h1>
      </div>
      <div id="divGroupC" className="control-section div-grp-c"  >
        <h1>Group-A</h1>
      </div> */}
      {LevelPopupModal()}
    </Wrapper>
  )
}

export default SectionC;

const Wrapper = styled.div`
.faicon-style{
  display:flex;
  label{
    margin-right : 10px;
  }
  .fa1{
    color : var(--clr-primary-5);
    cursor: pointer;
  }
}

.my-modal {
    width: 90vw ;
    max-width: 90vw;
} 
`
