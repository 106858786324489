import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { JsonTable } from '../index'
import { displayMessage } from '../../Utils/helper';
import { getRequest, ApiEnums, postRequest } from '../../services/DataRequestService';
import UserService from '../../services/UserService';

function SectionA1() {

  const [dbTypeList, setDbTypeList] = useState([]);
  const [databaseList, setDatabaseList] = useState([]);
  const [datatableList, setDatatableList] = useState([]);
  const [dbTypeSelected, setDbTypeSelected] = useState(null);
  const [databaseSelected, setDatabaseSelected] = useState(null);
  const [datatableSelected, setDatatableSelected] = useState(null);
  const [dbjsonData, setdbjsonData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [recordCount, setRecordCount] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);

  const refHostName = useRef(null);
  const refUserName = useRef(null);
  const refPortNumber = useRef(null);
  const refPassword = useRef(null);

  const inputRefs = useRef({});

  const [lblResponseMessage, setLblResponseMessage] = useState('');

  useEffect(() => {
    const init = async () => {
      let requestObject = {
        url: ApiEnums.databaselist,
        config: {
          params: {
            user_id: UserService.getUserID()
          }
        }
      }
      const responseData = await getRequest(requestObject);
      if (responseData?.dblist?.length > 0) {
        let options = responseData.dblist.map(elem => ({ label: elem, value: elem }))
        setDbTypeList(options);
      }
      else {
        displayMessage("danger", "Data not found ", `databaselist api have not fetched details`);
      }
    }
    init();
  }, [])



  const handleChangeDbType = (selected) => {
    refUserName.current.value = '';
    refPassword.current.value = '';
    refPortNumber.current.value = '';
    setDatabaseList([]);
    setDatatableList([]);
    setDatabaseSelected(null);
    setDatatableSelected(null);
    setLblResponseMessage('');
    setDbTypeSelected(selected);
  }

  const handleChangeDatabase = (selected) => {
    setDatabaseSelected(selected);
    setDatatableSelected(null);
    let { value } = selected;
    let datatablesOptions = dbjsonData.filter(elem => elem.database === value)
      .flatMap(ele => ele.datatables)
      .map(ele => ({ label: ele, 'value': ele }));
    setDatatableList(datatablesOptions);
  }

  const handleChangeDatatable = async (selected) => {
    setDatatableSelected(selected);
    setTableData([]);
    setRecordCount(null);
    let requestObject = {
      url: ApiEnums.getsampledata,
      data: {
        user_id: UserService.getUserID(),
        db_type: dbTypeSelected.value,
        dbusername: refUserName.current.value,
        dbuserpwd: refPassword.current.value,
        dbuserhostname: refHostName.current.value,
        dbuserport: refPortNumber.current.value,
        dbuserdb: databaseSelected.value,
        dbusertable: selected.value
      }
    }
    const responseData = await postRequest(requestObject);

    if (responseData?.status === 'success') {
     
      setTableData(curr => curr = responseData?.output?.sample_data);
      setRecordCount(curr => curr = responseData?.output?.record_count);
    } else {
      displayMessage("danger", "Data not found ", `Data not found for selected datatable`);
    }
  }

  function IsEmptyControl(control, controlName) {
    if (control.current.value === '') {
      control.current.style.borderColor = 'red';
      return `${controlName} `;
    } else {
      control.current.style.borderColor = 'black';
      return '';
    }
  }

  const btnOnClickBtnConnect = async (e) => {
    e.preventDefault();

    // If button is already clicked, return early
    if (buttonClicked) {
      return;
    }
    setButtonClicked(true);



    setDatabaseList([]);
    setDatatableList([]);
    setDatabaseSelected(null);
    setDatatableSelected(null);
    setTableData(null);
    setLblResponseMessage('');

    let errText = '';


    if (dbTypeSelected === null) {
      errText += 'DB Type ';
    }

    errText += IsEmptyControl(refHostName, 'HostName');
    errText += IsEmptyControl(refUserName, 'UserName');
    errText += IsEmptyControl(refPassword, 'Password');
    errText += IsEmptyControl(refPortNumber, 'PortNumber');

    if (errText?.trim()?.length > 0) {
      setLblResponseMessage(`please enter ${errText}`);
      setButtonClicked(false);
      return;
    }


    let requestObject = {
      url: ApiEnums.dataingestiondb,
      config: {
        params: {
          user_id: UserService.getUserID(),
          db_type: dbTypeSelected.value,
          dbusername: refUserName.current.value,
          dbuserpwd: refPassword.current.value,
          dbuserhostname: refHostName.current.value,
          dbuserport: refPortNumber.current.value
        }
      }
    }
    const responseData = await postRequest(requestObject);

    if (responseData?.status === 'success') {

      displayMessage("success", "Database Connection", 'Connected Successfully');
      if (responseData?.output !== null) {
        let databaselist = responseData.output?.map(val => ({ label: val.database, 'value': val.database }));
        setDatabaseList(databaselist);
        setdbjsonData(curr => curr = responseData?.output);
        setLblResponseMessage('Connected Successfully');
      }
    } else {
      setDatabaseList([]);
      setdbjsonData([]);
      setDatatableList([]);
      setDatabaseSelected(null);
      setDatatableSelected(null);
      displayMessage("danger", "Database Connection", `Connection Failed for ${dbTypeSelected.value}`);
      setLblResponseMessage(responseData.output);
    }

    setButtonClicked(false);
  }

  const handleInputOnBlur = (e) => {
    const { value, style } = e.target;
    if (value === '') {
      style.borderColor = 'red';
    } else {
      style.borderColor = 'black';
    }
  }
  return (
    <Wrapper>

      <div className="control-section control-width"  >
        <label className='mandatory lbl-for-tb' >Database Type </label>
        <Select
          key="dbType"
          options={dbTypeList}
          onChange={handleChangeDbType}
          value={dbTypeSelected}
          className=""
        />
      </div>

      <div className="control-section control-width"  >
        <label className='mandatory lbl-for-tb' >Host Name</label>
        <input ref={refHostName} type="text" name="hostname" id="hostname" onBlur={handleInputOnBlur} autoComplete='off' />
      </div>

      <div className="control-section control-width"  >
        <label className='mandatory lbl-for-tb' >User Name </label>
        <input ref={refUserName} type="text" name="userName" id="userName" onBlur={handleInputOnBlur} autoComplete="off" />
      </div>
      <div className="control-section control-width"  >
        <label className='mandatory lbl-for-tb' >Password</label>
        <input ref={refPassword} type="password" name="password" id="password" onBlur={handleInputOnBlur} autoComplete="off" />
      </div>
      <div className="control-section control-width"  >
        <label className='mandatory lbl-for-tb' >Port Number</label>
        <input ref={refPortNumber} type="number" name="portNumber" id="portNumber" onBlur={handleInputOnBlur} autoComplete="off" />
      </div>


      <div className="control-section"  >
        <button onClick={btnOnClickBtnConnect} className='button-1'  > {buttonClicked ? 'Connecting...' : 'Connect'}</button>
      </div>
      <div className="control-section"  >
        <label id="lblMessage">{lblResponseMessage}</label>
      </div>

      {databaseList?.length > 0 && <div>
        <div className="control-section control-width"  >
          <label className='mandatory lbl-for-tb' >Select Database</label>
          <Select
            key="dbType"
            options={databaseList}
            onChange={handleChangeDatabase}
            value={databaseSelected}
            className=""
          />
        </div>

        <div className="control-section control-width"  >
          <label className='mandatory lbl-for-tb' >Select Datatable</label>
          <Select
            key="dbType"
            options={datatableList}
            onChange={handleChangeDatatable}
            value={datatableSelected}
            className=""
          />
        </div>
      </div>}



      <div className="control-section">
        <div className={`custom-scrollbar ${tableData?.length <= 0 ? 'hide' : ''}`}  >
          {tableData?.length > 0 &&
            <> {<span className='text-info1' >{`Number of Records : ${recordCount}`} </span>}
              <JsonTable data={tableData} />
            </>
            }
        </div>
      </div>

    </Wrapper>
  )
}

export default SectionA1;

const Wrapper = styled.div`

.text-info1{
    font-size: 16px;
    margin-bottom: 5px;
    display: inline-block;
    color: var(--clr-primary-5);
    font-weight: 600;
}
 .control-width {
  width :500px;
  input {
    width : 500px;
  }
}

.hide{
  display:none;
}

#lblMessage{
   color : red;
   font-size : 14px;
}

.table-container {
  width:100%;
  height:30vh;
  overflow-y:auto;
  margin: 0 0.5rem;
  margin-bottom : 2rem;
}

.switches-container {
    width: 16rem;
    position: relative;
    display: flex;
    position: relative;
    background: var(--switches-bg-color);
    border-radius: 0rem;
    padding: 0.5rem;
    font-size: 18px;
}

/* input (radio) for toggling. hidden - use labels for clicking on */
.switches-container input {
    visibility: hidden;
    position: absolute;
    top: 0;
}

/* labels for the input (radio) boxes - something to click on */
.switches-container label {
    width: 50%;
    padding: 0;
    margin: 0;
    height:100%;
    text-align: center;
    cursor: pointer;
    color: var(--switches-label-color);
}

/* switch highlighters wrapper (sliding left / right) 
    - need wrapper to enable the even margins around the highlight box
*/
.switch-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    padding: 0.15rem;
    z-index: 3;
    transition: transform .5s cubic-bezier(.77, 0, .175, 1);
    /* transition: transform 1s; */
}

/* switch box highlighter */
.switch {
    border-radius: 0rem;
    background: var(--switch-bg-color);
    height: 100%;
}

/* switch box labels
    - default setup
    - toggle afterwards based on radio:checked status 
*/
.switch div {
    width: 100%;
    text-align: center;
    opacity: 0;
    display: block;
    color: var(--switch-text-color) ;
    transition: opacity .2s cubic-bezier(.77, 0, .175, 1) .125s;
    will-change: opacity;
    position: absolute;
    top: 0;
    left: 0;
}

/* slide the switch box from right to left */
.switches-container input:nth-of-type(1):checked~.switch-wrapper {
    transform: translateX(0%);
}

/* slide the switch box from left to right */
.switches-container input:nth-of-type(2):checked~.switch-wrapper {
    transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.switches-container input:nth-of-type(1):checked~.switch-wrapper .switch div:nth-of-type(1) {
    opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.switches-container input:nth-of-type(2):checked~.switch-wrapper .switch div:nth-of-type(2) {
    opacity: 1;
}
`