const filterEnums = {
    states : {
        levelNumber : 1,
        name : 'states',
        listcode : 'state',
        group : 'group-a'
    },
    stores : {
        levelNumber : 2,
        name : 'stores',
        listcode : 'store',
        group : 'group-a'
    },
    products : {
        levelNumber : 3,
        name : 'products',
        listcode : 'item',
        group : 'group-b'
    },
    categories : {
        levelNumber : 1,
        name : 'categories',
        listcode : 'cat',
        group : 'group-b'
    },
    departments : {
        levelNumber : 2,
        name : 'departments',
        listcode : 'dept',
        group : 'group-b'
    },
    models : {
        levelNumber : 1,
        name : 'models',
        listcode : 'model',
        group : ''
    }
}

export default filterEnums;