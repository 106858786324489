import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { getValue , isEmpty , setValue , displayMessage  } from '../../Utils/helper'
import { ApiEnums, postRequest } from '../../services/DataRequestService';
import LoaderImage from '../../assets/loader.gif'


function GetCombinations() {
  let states = getValue("session", "form", "states");
  let stores = getValue("session", "form", "stores");
  //  stores = stores.filter(e => e.levelNumber === "2");

  let categories = getValue("session", "form", "categories");
  let departments = getValue("session", "form", "departments");
  //   departments = departments.filter(e => e.levelNumber === "2");
  let products = getValue("session", "form", "products");
  //   products = products.filter(e => e.levelNumber === "3");

  let groupA = states.concat(stores);
  let groupB = categories.concat(departments).concat(products);


  let groupAList = getGroupArray(groupA);
  let groupBList = getGroupArray(groupB);


  let combinations = [];
  if (groupAList?.length > 0){
    for (let itemA of groupAList) {
      if (groupBList?.length > 0) {
        for (let itemB of groupBList) {
          combinations.push(itemA.concat(itemB));
        }
      } else {
        combinations.push(itemA);
      }
    }
  }else{
    if (groupBList?.length > 0) {
      for (let itemB of groupBList) {
        combinations.push(itemB);
      }
    }
  } 

  return combinations;
}

function getGroupArray(groupData) {

  let groupaArray = []
  let indeterminateList = groupData.filter(a => a.indeterminate)
  let listA = groupData.filter(a => !a.indeterminate && a.checked)

  for (let item of listA) {
    if (item.leafnode && !(indeterminateList.some(b => item.id == b.id && item.levelNumber == b.levelNumber))) {
      let traverseArray = [];
      let itm = item;

      do {
        traverseArray.push({ name: itm.name, group: itm.group, type: itm.type, id: itm.id });
        itm = listA.find(e => e.id == itm.parent && e.levelNumber == (Number(itm.levelNumber) - 1));
      } while (itm)

      groupaArray.push(traverseArray.reverse());
    } else {
      continue;
    }
  }
  return groupaArray;
}

function SectionD(props) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      let form = JSON.parse(sessionStorage.getItem('form'));
      let startDate = form['start_date'];
      let cutOffDate = form['cut_off_date'];
      let endDate = form['end_date'];
      let granularity = form['granularity'];
      let _combinations = GetCombinations();
   
      setLoading(true);

      setValue('session','form','combinations',[]); //resetting form.combinations in session storage

      if(!_combinations?.length){
        displayMessage('error','Provide Business Criteria Configuration', 'Please provide inputs in business criteria configurations');
      }

      let requestObject = {
        url: ApiEnums.count_record,
        data : {
          is_validated: false,
          start_date: startDate,
          end_date: endDate,
          cut_off_date: cutOffDate,
          granularity: granularity,
          combinations: _combinations
        }
      }
      const responseData = await postRequest(requestObject);
      setValue('session','all_combinations',null,responseData.combinations);
 
      setData(responseData);
      //form['combinations'] = testObject;
      //setData(testObject)

      setLoading(false);
    }
    init();
  }
    , [])


    const cbCombinationOnChangeEventHandler = (e) => {
      let combinationId = e.target.id;
      if(e.target.checked){
        let allCombinations = getValue('session','all_combinations');
       
        if(allCombinations?.length > 0){          
            let selectedCombination = allCombinations.find(elem => elem.comb_id == combinationId);
            if(selectedCombination){
              let formCombinations = getValue('session','form','combinations');
              formCombinations.push(selectedCombination);
              setValue('session','form','combinations',formCombinations)
            }else{
              //exception case
            }
        }else{
          //exception : data doesn't exists in session storage.        
        }
      }else{
        let formCombinations = getValue('session','form','combinations');
        let updatedCombinations = formCombinations.filter(elem => elem.comb_id != combinationId);
        setValue('session','form','combinations',updatedCombinations);
      }
    }

    if(loading){
      return <>
      <img src={LoaderImage} width="100px" height="100px" />
      <br/>
      <span> We are processing... please wait. </span>
      </>
    }
    
  return (
    <Wrapper> 
      {        
        !isEmpty(data)?
          <div className='f1'>
            <div className='main'>
              <table className="table cust-table">
                <thead>
                  <tr>
                    <th scope="col">Combination</th>
                    <th scope="col">Trained</th>
                    <th scope="col">Trained Thershold</th>
                    <th scope="col">Test </th>
                    <th scope="col">Test Thershold</th>
                    <th scope="col">Select</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    isEmpty(data) ?
                      <></> :
                      <>
                        {
                        data?.combinations.map((elem, index) => (<tr key={index}>
                          <td>                           
                              {
                                elem?.element?.map((el, index) => (<span key={index} className={el.group} >{el.name}</span>))
                              }                           
                          </td>
                          <td>{elem.train}</td>
                          <td>{elem.train_th}</td>
                          <td>{elem.test}</td>
                          <td>{elem.test_th}</td>
                          <td> <input 
                                  type="checkbox" 
                                  name="list" 
                                  id={elem.comb_id} 
                                  disabled={elem.is_disabled}
                                  onChange={cbCombinationOnChangeEventHandler}
                               />
                          </td>
                        </tr>))
                        }
                      </>
                  }
                </tbody>
              </table>
            </div>
          </div>
          :
          <label>please select the business criteria configurations</label>
      }
    </Wrapper>
  )
}

export default SectionD;

const Wrapper = styled.div`
.main{
  overflow:auto;
  max-height:20rem;
  
}
.f1{
  display : flex;
  flex-direction:column;
}

label{
  text-align: left;
}
ol{
  list-style-type:none;
  list-style-position: outside;
  padding-left:3rem;
}

.list{  
  display : flex;
  flex-direction:column;
  border:0.5px solid var(--clr-grey-8);
  border-radius:var(--radius);
  ul{
  display : flex;
  flex-direction:row;
  li{
    padding-right : 1rem;
    font-size:14px;
  }
  li:not(:last-child)::after{
    content : '>';
    padding-left : 1rem;
  }  
  }  
}
tbody td{
    font-size:14px;
}

tbody td:first-child{
    text-align:left;
    font-size:14px;
}

td span:not(:last-child)::after{
  content: ' > '
}
.group-a {
  color :var(--group-a);
}
.group-b {
  color :var(--group-b);
}
`

const testObject = {
  "is_validated": true,
  "start_date": "01-05-2016",
  "end_date": "15-05-2016",
  "cut_off_date": "08-05-2016",
  "granularity": "daily",
  "combinations": [
      {
          "comb_id": 1,
          "comb_details": {
              "state_id": 1,
              "store_id": 2,
              "cat_id": 1
          },
          "element": [
              {
                  "name": "California",
                  "group": "group-a",
                  "type": "state",
                  "id": 1
              },
              {
                  "name": "CA_2",
                  "group": "group-a",
                  "type": "store",
                  "id": 2
              },
              {
                  "name": "FOODS",
                  "group": "group-b",
                  "type": "cat",
                  "id": 1
              }
          ],
          "train": 8,
          "train_th": 365,
          "test": 7,
          "test_th": 1,
          "is_disabled" : true
      }
  ]
}