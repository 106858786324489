import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import '../../assets/sass/tabs.scss'
import GraphicalViewContainer from './GraphicalViewContainer'
import TabularViewContainer from './TabularViewContainer'
import ErrorMetrics from './ErrorMetrics'
import { isEmpty } from '../../Utils/helper';
import Select from 'react-select';
import UserService from '../../services/UserService'
import { getRequest, ApiEnums } from '../../services/DataRequestService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

export default function MainLayout({ masterData, combinations, workflowId, loadingBar , closeHandler, IsClose }) {

  const [combinationName, setCombinationName] = useState(null);
  const [ReportData, setReportData] = useState({});
  const [flag, setFlag] = useState(false);



  const options = combinations.map(ob => ({

    label: ob.params.map(elem => elem.entityName).join(' '),
    value: {
      name: ob.name,
      params: ob.params,
      id: ob.id
    }
  }))

  const formatOptionLabel = (data, { context }) => {
    return context === "menu" ? <div className="select-list" style={{ flex: "10" }}>
      {data.value.name.toLowerCase()}
      <div>
        {data.value.params.map((param, i) => (<span 
              key={i}
              className={param.group}
              style={ { color : (param.group === 'group-a')?'red':'green'}}
              
              >{param.entityName}</span>
        ))}
      </div>
    </div>
      :
      <>{data.value.name.toLowerCase()} </>
  };



  const combinationSelectChangeHandler = async (event) => {
    setCombinationName(curr => curr = event.value.name);
    loadingBar.current.continuousStart();
    let requestObject = {
      url: ApiEnums.wf_combination_report,
      config: {
        params: {
          user_id: UserService.getUserID(),
          workflow_id: workflowId,
          combination_id: event.value.id
        }
      }
    }
    const responseObject = await getRequest(requestObject);
    if (responseObject !== "error") {
      setReportData(curr => curr = responseObject);
    }
    document.getElementById("sectionB").style.display = "block";
    document.getElementById("ul1").style.display = "flex";
    loadingBar.current.complete();
  }

  const cbTabchangeHandler = (e) => {
    setFlag(!flag);
    let elemId = e.target.id;
    switch (elemId) {
      case 'tab1':
        document.getElementById('lbl1').classList.add('active');
        document.getElementById('lbl2').classList.remove('active');
        document.getElementById('lbl3').classList.remove('active');
        break;
    
      case 'tab2':
        document.getElementById('lbl1').classList.remove('active');
        document.getElementById('lbl2').classList.add('active');
        document.getElementById('lbl3').classList.remove('active');
        break;
    
      case 'tab3':
        document.getElementById('lbl1').classList.remove('active');
        document.getElementById('lbl2').classList.remove('active');
        document.getElementById('lbl3').classList.add('active');
        break;
    
      default:
        // Handle the case when elemId does not match any of the specified cases
        break;
    }
  }

  return (
    <Wrapper >
      <div className='sect'>
        <div className="sect-z">
          {
             IsClose &&  <div id="btnOpen" onClick={closeHandler} >
               <FontAwesomeIcon icon={faBars} size="lg" fixedWidth />
             </div>
          }         
        </div>
        <div className="sect-a">
          <div id="combinations" >
            {/* <CombinationList data={combinations} /> */}
            <Select
              closeMenuOnSelect={true}
              onChange={combinationSelectChangeHandler}
              formatOptionLabel={formatOptionLabel}
              isSearchable
              options={options}
            />
          </div>

          {flag || true ? <ul id="ul1" style={{ display: 'none' }}  >
            <li title="Tabular View">
              <label htmlFor="tab1" id="lbl1"  role="button">
                <span>Tabular View</span>
              </label>
            </li>
            <li title="Graphical View">
              <label htmlFor="tab2" id="lbl2" className='active' role="button">
                <span>Graphical View</span>
              </label>
            </li>
            <li title="Error Metrics">
            <label htmlFor="tab3" id="lbl3" role="button">
                <span>Error Metrics</span>
              </label>
            </li>
          </ul> : <></>}

        </div>
        <div className="sect-b">
          <div id="sectionB" className='sectionB' style={{ display: 'none' }} >
            <div className="stvTabs">
              <input type="radio" id="tab1" name="tab-control"  onChange={cbTabchangeHandler} ></input>
              <input type="radio" id="tab2" name="tab-control" defaultChecked onChange={cbTabchangeHandler}></input>
              <input type="radio" id="tab3" name="tab-control" onChange={cbTabchangeHandler}></input>
             
              {/* <ul >
              <li title="Tabular View">
                <label htmlFor="tab1"  role="button">
                  <span>Tabular View</span>
                </label>
              </li>
              <li title="Graphical View">
                <label htmlFor="tab2" role="button">
                  <span>Graphical View</span>
                </label>
              </li>
            </ul> */}
              <div className="content">
                <section>
                  <h2>Tabular View</h2>
                  {
                    !isEmpty(ReportData) ? <TabularViewContainer data={ReportData} CombinationName={combinationName} /> : <>No Data Found</>
                  }
                </section>
                <section>
                  <h2>Graphical View</h2>
                  {
                    !isEmpty(ReportData) ? <GraphicalViewContainer data={ReportData} /> : <>No Data Found</>
                  }
                </section>
                <section>
                <h2>Error Metrics</h2>                
                {
                    !isEmpty(ReportData) ? <ErrorMetrics data={ReportData?.error_metrics} CombinationName={combinationName} /> : <>No Data Found</>
                  }
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}





const Wrapper = styled.div`
.sect {
display : flex;
flex-direction:column;
height : calc(100% - 5px);
border:1px solid #e4e7ed;
margin : 5px;
padding-right:0px;
padding-left:0px;
background : white;
-webkit-user-select: none;
user-select: none;
}

.sect-a{
  display:grid;
  grid-template-columns: 75% auto;
  padding:1rem;  
  width:100%;
  :nth-child(3) , :nth-child(2){
    justify-content : center;
    align-items:center;
  }
  ul{
    display:flex;
    flex-direction:row;
    padding-left:0rem;
    justify-content:center;
    width:100%;
    margin-bottom:0px;
  }
}

.sect-b{
  border-top : 1px solid black;
  margin:0 1rem;
}

.sectionB{
  display:grid;
  grid-template-areas:
  'buttons'
  'graph';
}
.sectionB-A{
  display:flex;
  flex-direction:row;
  justify-content:flex-end;
}
.select-list{
  margin-bottom:0px;
  div {
    display:flex;
    justify-content:flex-start;
    span {    
    text-transform:lowercase;
    font-size:14px; 
    &:first-child(){
      margin:0 0px;
    padding:0 0px;
    }
    :not(:last-child)::after{
      content : '>';
      padding :0 5px;
    }

  }
  } 
}

#combinations > div{
  width :90%;
  align-items : center;
}

#ul1 {
  width:100%;
  li:first-child > label{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;    
  }
  li:last-child  > label{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  label {
      float: left;
      padding: .5em 1em;
      cursor: pointer;
      border: 1px solid var(--clr-primary-9);
      margin-right: -1px;
      color: var(--clr-primary-5);
      background-color: var(--clr-primary-10);
      
      div{
        height: fit-content;
        
      }
    }
  
  .active {
  background-color: var(--clr-primary-3);
  span {
   
    color : var(--clr-primary-10);
  }
}
}

h1{
    padding-bottom:1.5rem;
    color: var(--clr-primary-2);
}

#btnOpen{
  width:fit-content;
  margin:5px 0px 0px 5px;
  cursor: pointer; 
  color:var(--clr-primary-1);
  :hover{
    color:var(--clr-primary-3);
  }
}

`
