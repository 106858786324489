import { Store } from 'react-notifications-component';

var getSiblings = function (elem) {

  // Setup siblings array and get the first sibling
  var siblings = [];
  var sibling = elem.parentNode.firstChild;

  // Loop through each sibling and push to the array
  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== elem) {
      siblings.push(sibling);
    }
    sibling = sibling.nextSibling
  }

  return siblings;

};

//https://dev.to/urielbitton/writing-a-parents-function-using-vanilla-js-2pgc

function getParents(el, parentSelector) {
  if (parentSelector === undefined) {
    parentSelector = document;
  }

  var parents = [];
  var p = el.parentNode;
  while (p !== parentSelector) {
    var o = p;
    parents.push(o);
    p = o.parentNode;
  }
  parents.push(parentSelector);
  return parents;
}


function displayMessage(type, title, message) {
  Store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: "top",
    container: "bottom-left",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  });
}

//https://jetrockets.com/blog/rmvzzosmz9-rename-the-key-name-in-the-javascript-object
const renameKey = (object, key, newKey) => {
  const clonedObj = clone(object);
  const targetKey = clonedObj[key];
  delete clonedObj[key];
  clonedObj[newKey] = targetKey;
  return clonedObj;
};

const clone = (obj) => Object.assign({}, obj);


function getLocalObject(key) {
  let value = localStorage.getItem(key);
  let output = null;
  if (value) {
    output = JSON.parse(value);
  }
  return output;
}

function getSessionObject(key) {
  let value = sessionStorage.getItem(key);
  let output = null;
  if (value) {
    output = JSON.parse(value);
  }
  return output;
}

/*
storageType : 'local' | 'session'
storageObjectName : string

*/
function setValue(storageType, storageObjectName, propertyName, value) {
  let storageObjectStr = null;
  if (storageType.toLowerCase() === 'local') {
    storageObjectStr = localStorage.getItem(storageObjectName);
  } else if (storageType.toLowerCase() === 'session') {
    storageObjectStr = sessionStorage.getItem(storageObjectName);
  }
  let storeObject = null;
  if (storageObjectStr !== null) {
    storeObject = JSON.parse(storageObjectStr);
    if(propertyName && storeObject){
      storeObject[propertyName] = value;
    }else if(!propertyName && storeObject){
      storeObject = value;
    }else{
      return false; // when propertyName is defined but not the storageObjectName
    }
  }else{
    storeObject = {};
    if(propertyName && storageObjectName){
      storeObject[propertyName] = value;
    }else if(!propertyName && storeObject){
      storeObject = value;
    }else{
      return false; // when propertyName is defined but not the storageObjectName
    }
  }

  if (storageType.toLowerCase() === 'local') {
    localStorage.setItem(storageObjectName, JSON.stringify(storeObject));
  } else if (storageType.toLowerCase() === 'session') {
    sessionStorage.setItem(storageObjectName, JSON.stringify(storeObject));
  }
  return true;
}

function getValue(storageType, storageObjectName, propertyName) {
  let storageObjectStr = null;
  if (storageType.toLowerCase() === 'local') {
    storageObjectStr = localStorage.getItem(storageObjectName);
  } else if (storageType.toLowerCase() === 'session') {
    storageObjectStr = sessionStorage.getItem(storageObjectName);
  }
  if (storageObjectStr !== null) {
    let storeObject = JSON.parse(storageObjectStr); //https://stackoverflow.com/questions/42494823/json-parse-returns-string-instead-of-object
    if (propertyName && storeObject.hasOwnProperty(propertyName)) {
      return storeObject[propertyName];
    } else {
      return storeObject;
    }
  }
  else {
    return false;
  }
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export {
  getSiblings,
  getParents,
  displayMessage,
  renameKey,
  getLocalObject,
  getSessionObject,
  isEmpty,
  setValue,
  getValue
}