import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { MultiLevelCbControl, ErrorText } from '.'
import { Tabs, Tab } from '../common/Tabs'
import { setValue, getValue, getSiblings, renameKey } from '../../Utils/helper'
import filterEnums from '../../Utils/enums'
import $ from 'jquery'
import { MasterListBasedOnPrevSelect } from '../../services/DataManipulationService';
import businessTabStyles from '../../assets/css/business-tabs.module.css'
import { 
  getStateList, 
  getStoreList, 
  getCategoryList, 
  getDepartmentList, 
  getProductsList, 
  getModelList } from '../../Utils/filterScripts';
import { getRequest, ApiEnums } from '../../services/DataRequestService';



function SectionC_Old() {
  const TabsRef = useRef();

  const [data, setData] = useState();
  const [states, setStates] = useState([]);
  const [stores, setStores] = useState([]);
  const [categories, setCategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [products, setProducts] = useState([]);
  const [models, setModels] = useState([]);
  const [masterList, setMasterList] = useState([]);
  const [isSubListExists, setIsSubListExists] = useState(false);

  const [tabState, setTabState] = useState({ activeTab: TabsRef.current?.props.children[0].props.label });

  useEffect(()=>{
    const func = async() => {
      var masterDataStr = localStorage.getItem("masterdata");
      if (!masterDataStr){
        let masterDataLocalString = ''

        let requestObject = {
          url: ApiEnums.masterdata
        }
        const responseData = await getRequest(requestObject);
    
    
        let _states = getStateList(responseData.state);
        let _stores = getStoreList(responseData.store, responseData.state);
        let _categories = getCategoryList(responseData.category);
        let _departments = getDepartmentList(responseData.department, responseData.category);
        let _products = getProductsList(responseData.item, responseData.department, responseData.category);
        let _models = getModelList();
        let masterDataLocalObject = {
          states: _states,
          stores: _stores,
          categories: _categories,
          departments: _departments,
          products: _products,
          models: _models,
          data_availability : { from : responseData.available_from , to:responseData.available_to }
        }
        masterDataLocalString = JSON.stringify(masterDataLocalObject);
        localStorage.setItem("masterdata", masterDataLocalString);
        masterDataStr = masterDataLocalString;
      }
      let masterData = JSON.parse(masterDataStr);
      setData(masterData);
      return { masterData }
    }
    func();
  },[]);

  useEffect(()=>{
    let formStr = sessionStorage.getItem('form');
    if (formStr) {
      let form = JSON.parse(formStr)
      setStates(states => states = form.states);
      setStores(curr => curr = form.stores);
      setCategories(curr => curr = form.categories);
      setDepartments(curr => curr = form.departments);
      setProducts(curr => curr = form.products);
    }else{
      console.log('session data not available')
    }
  },[])

  

  if(!data) return false;

  const changeTab = (tab) => {
    let SelMasterList = [];
    switch (tab.toLowerCase()) {

      case 'states': setMasterList(curr => curr = data.states);
        break;

      case 'stores':
        SelMasterList = MasterListBasedOnPrevSelect(data.stores, filterEnums.states);
        setMasterList(curr => curr = SelMasterList);
        break;

      case 'categories':
        setMasterList(curr => curr = data.categories);
        break;

      case 'departments': SelMasterList = MasterListBasedOnPrevSelect(data.departments, filterEnums.categories);
        setMasterList(curr => curr = SelMasterList);
        break;

      case 'products': SelMasterList = MasterListBasedOnPrevSelect(data.products, filterEnums.departments);
        setMasterList(curr => curr = SelMasterList);
        break;

      default: break;
    }
    setTabState({ activeTab: tab });
  };

  function IsSubCategoriesSelected(currListEnum, subListEnum, selectedValue) {
    let response = false;
    selectedValue = selectedValue.replace(currListEnum.listcode, subListEnum.listcode);
    let selChildrens = getValue("session", "form", subListEnum.name);
    if (selChildrens) {
      response = selChildrens.some(e => [selectedValue].includes(e._id) && (e.indeterminate || e.checked));
    }
    return response;
  }

  const CheckBoxOnChangeHandler = (filterEnum, e) => {

    let selObj = {};
    var checked = e.target.checked,
      container = e.target.parentNode;

    if (!checked) {
      let currId = e.target.id;
      let flag = false;
      switch (filterEnum.name) {
        case 'states': flag = IsSubCategoriesSelected(filterEnums.states, filterEnums.stores, currId);
          break;
        case 'stores': break;
        case 'categories': flag = IsSubCategoriesSelected(filterEnums.categories, filterEnums.departments, currId);
          break;
        case 'departments': flag = IsSubCategoriesSelected(filterEnums.departments, filterEnums.products, currId);
          break;
        case 'products': break;
        default: break;
      }
      setIsSubListExists(flag);
      if (flag) {
        return;
      }
    }

    var cbs = container.querySelectorAll('input[type="checkbox"]');
    const calcId = (id) => id = Number(id.split('-')[1]);

    const isLeafNode = (_id) => {
      let elem = Object.keys(filterEnums).find(elem => filterEnums[elem].name === filterEnum.name);
      let levelNumber = filterEnums[elem].levelNumber;
      let level_id = _id.split('-')[0].at(-1);
      if (levelNumber == level_id) {
        return true;
      } else {
        return false;
      }
    }

    [].forEach.call(cbs, function (cb) {

      cb.checked = checked;
      cb.indeterminate = false;
      selObj[cb.id] = {
        tempid: calcId(cb.id),
        id: calcId(cb.id),
        _id: cb.id,
        checked: checked,
        indeterminate: false,
        leafnode: isLeafNode(cb.id),
        parent: cb.dataset.parent,
        levelNumber: cb.dataset.levelnumber,
        group : cb.dataset.group,
        name : cb.dataset.name,
        type : cb.dataset.type
      };
    });

    function checkSiblings(el) {
      let parent = el.parentNode.parentNode;
      let all = true;


      let elSiblings = getSiblings(el);
      for (let sibling of elSiblings) {
        let returnValue = all = ($(sibling).children('input[type="checkbox"]').prop("checked") === checked);
        if (returnValue) {
          continue;
        } else {
          break;
        }
      }
      if (all && checked) {

        let _p = parent.querySelectorAll(':scope > input[type="checkbox"]')
        if (_p !== null) {
          for (let i = 0; i < _p.length; i++) {
            _p[i].checked = checked;
            _p[i].indeterminate = false;
            selObj[_p[i].id] = {
              tempid: calcId(_p[i].id),   
              id: calcId(_p[i].id),  
              _id: _p[i].id,
              checked: _p[i].checked,
              indeterminate: _p[i].indeterminate,
              leafnode: isLeafNode(_p[i].id),
              parent: _p[i].dataset.parent,
              levelNumber: _p[i].dataset.levelnumber,
              group : _p[i].dataset.group,
              name :  _p[i].dataset.name,
              type :  _p[i].dataset.type
            };
          }

        }
        checkSiblings(parent);

      } else if (all && !checked) {

        let _p1 = parent.querySelectorAll(':scope > input[type="checkbox"]');
        if (_p1 !== null) {
          for (let i = 0; i < _p1.length; i++) {
            _p1[i].checked = checked;
            _p1[i].indeterminate = parent.querySelectorAll('input[type="checkbox"]:checked')?.length > 0;
            selObj[_p1[i].id] = {
              tempid: calcId(_p1[i].id),
              id: calcId(_p1[i].id),
              _id: _p1[i].id,
              checked: _p1[i].checked,
              indeterminate: _p1[i].indeterminate,
              leafnode: isLeafNode(_p1[i].id),
              parent: _p1[i].dataset.parent,
              levelNumber: _p1[i].dataset.levelnumber,
              group : _p1[i].dataset.group,
              name :  _p1[i].dataset.name,
              type :  _p1[i].dataset.type
            };
          }
        }
        checkSiblings(parent);

      } else {

        let _p2List = $(el).parents("li");
        for (let i = 0; i < _p2List.length; i++) {
          let _p2 = _p2List[i].querySelector(':scope > input[type="checkbox"]');
          _p2.indeterminate = true;
          _p2.checked = false;
          selObj[_p2.id] = {
            tempid: calcId(_p2.id),
            id: calcId(_p2.id),
            _id: _p2.id,
            checked: _p2.checked,
            indeterminate: _p2.indeterminate,
            leafnode: isLeafNode(_p2.id),
            parent: _p2.dataset.parent,
            levelNumber: _p2.dataset.levelnumber,
            group : _p2.dataset.group,
            name :  _p2.dataset.name,
            type :  _p2.dataset.type
          };
        }
      }
    }
    checkSiblings(container);

    switch (filterEnum.name) {
      case 'states': updateFilterState(selObj, states, setStates, filterEnum.name); break;
      case 'stores': updateFilterState(selObj, stores, setStores, filterEnum.name); break;
      case 'categories': updateFilterState(selObj, categories, setCategories, filterEnum.name); break;
      case 'departments': updateFilterState(selObj, departments, setDepartments, filterEnum.name); break;
      case 'products': updateFilterState(selObj, products, setProducts, filterEnum.name); break;
      case 'models': updateFilterState(selObj, models, setModels, filterEnum.name); break;

      default: break;
    }

  }

  function updateFilterState(selObj, filterState, setFilterState, filterName) {
    // //check and update in state array.
    let selArray = modifyKeyValue_Id(filterName, Object.values(selObj));
    let prevSelect = [...filterState];
    for (let item of selArray) {
      //if select contains 'item', update it else add that 'item' to select 
      let res = prevSelect.find(elem => elem._id == item._id);
      if (res === undefined) {
        prevSelect.push(item)
      } else {
        let i = prevSelect.findIndex(elem => elem._id === res._id)
        prevSelect.splice(i, 1);
        prevSelect.push(item);
      }
    }

    setFilterState(current => current = prevSelect);
    setValue('session', 'form', filterName, prevSelect);
  }

  function modifyKeyValue_Id(filterName, inputArray) {
    let listCode = null;
    Object.keys(filterEnums).forEach(elem => {
      if (filterEnums[elem].name === filterName) {
        listCode = filterEnums[elem].listcode;
      }
    });
    inputArray = inputArray.map(elem => elem = renameKey(elem, "tempid", `${listCode}_id`))
    return inputArray;
  }

  return (
    <Wrapper>
      <Tabs name="bTabs" ref={TabsRef} changeHandler={changeTab} tabState={tabState} style={businessTabStyles} >
        <Tab label="States" name="Channels" >
          <MultiLevelCbControl data={data.states} changeHandler={CheckBoxOnChangeHandler} selections={states} filterEnum={filterEnums.states} />
          {
            isSubListExists ? <ErrorText>To deselect state first deselect all its stores under Stores tab</ErrorText> : <></>
          }
        </Tab>
        <Tab label="Stores" name="Customers">
          {0 == 0 ?
            <MultiLevelCbControl data={masterList} changeHandler={CheckBoxOnChangeHandler} selections={stores} filterEnum={filterEnums.stores} />

            :
            <ErrorText> please select </ErrorText>
          }
        </Tab>
        <Tab label="Categories" name="Categories" >
          <MultiLevelCbControl data={masterList} changeHandler={CheckBoxOnChangeHandler} selections={categories} filterEnum={filterEnums.categories} />
          {
            isSubListExists ? <ErrorText>To deselect category first deselect all its departments under Departments tab</ErrorText> : <></>
          }
        </Tab>
        <Tab label="Departments" name="Sub Categories" >
          {0 == 0 ?
            <>
              <MultiLevelCbControl data={masterList} changeHandler={CheckBoxOnChangeHandler} selections={departments} filterEnum={filterEnums.departments} />
              {
                isSubListExists ? <ErrorText>To deselect department first deselect all its products under Products tab</ErrorText> : <></>
              }
            </> :
            <ErrorText prev="States" curr="Stores" />
          }
        </Tab>
        <Tab label="Products" name="Items" >
          {0 == 0 ?
            <MultiLevelCbControl data={masterList} changeHandler={CheckBoxOnChangeHandler} selections={products} filterEnum={filterEnums.products} />
            :
            <ErrorText/>
          }
        </Tab>
      </Tabs>
    </Wrapper>
  )
}

export default SectionC_Old;

const Wrapper = styled.div`
button[id='States'] ,button[id='Stores'] , .group-a {
  color :var(--group-a);
  
}
button[id='Categories'],button[id='Departments'],button[id='Products'] , .group-b {
  color :var(--group-b);
}

input[type='checkbox'] {
  display:none;
}

input[type='checkbox'] +label:hover{
  background-color:var(--clr-primary-10);
  border-radius:0.4rem;
}

input[data-group='group-a']:checked +label{
   color:var(--group-a);
}

input[data-group='group-b']:checked +label{
   color:var(--group-b);
}

input[type='checkbox']:checked +label{
   background-color:var(--clr-primary-9);
   border-radius:0.4rem;
   padding:3px;
   margin:2px;
}

#tabContents{
  height:20rem;
}
`