import React, { useMemo, useEffect ,useRef } from 'react';
import styled from 'styled-components';
import { useTable } from 'react-table';
import '../../assets/css/table.css';
// import "datatables.net-jqui/js/dataTables.jqueryui.min.mjs"
// import $ from 'jquery';


function BasicTable({inputData,columnNames}) {
  //const tableRef = useRef(null);

    const columns = useMemo(() => columnNames, []);
    const data = useMemo(() => inputData, [])

    const tableInstance = useTable({
        columns ,
        data :inputData
    })

    const { getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow } = tableInstance;

        
  

    return (
        <Wrapper>
            {/* //ref={tableRef}  */}
            <table  id="table1" {...getTableProps()}
                >
                <thead className='cust-thead'>
                    {
                        headerGroups.map((headerGroup) => (<tr {...headerGroup.getHeaderGroupProps()} >
                            {
                                headerGroup.headers.map((column)=>(
                                    <th  {...column.getHeaderProps()} 
                                    style={{
                                        textAlign: column?.center ? 'center' : 'left' // Align center if the cell.column.center property is true
                                      }}
                                    >{ column.render('Header') }</th>
                                ))
                            }
                        </tr>))
                    }
                   
                </thead>
                <tbody {...getTableBodyProps()} >
                    {
                        rows.map(row => {
                            prepareRow(row)
                            return(
                                <tr {...row.getRowProps()}>
                                    {
                                        row.cells.map((cell)=>{
                                            return <td {...cell.getCellProps()}
                                            style={{
                                                textAlign: cell.column?.center ? 'center' : 'left' // Align center if the cell.column.center property is true
                                              }}
                                             >
                                                {cell.render('Cell')}
                                                </td>
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
           
                </tbody>
            </table>
        </Wrapper>
    )
}

export default BasicTable;


const Wrapper = styled.div`
font-size:14px;
.cust-thead{
    position: sticky;
    top: 0;
    background-color: var(--clr-primary-5); /* Background color for the fixed header */
}
`