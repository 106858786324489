const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '600px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
  }

  export const device = {
    mobileL: ` (max-width:${size.mobileL})`,   
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`
   
  };