import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

function FilterItem(props) {

  return (
    <Wrapper>
      {props.type === 'header' ?
        <section className="filterhead mainhead">
          <div className="filterbox">
            <span>{props.name}</span>

          </div>
        </section>
        :
        props.type === 'filterControl' ?
          <section className='mainhead'>
            <div className="sectionlayout contentstyle collapsible">
              <span>{props.name}</span>
              <span className="expanded">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512">
                  <path d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z" />
                </svg>
              </span>
            </div>
            <div className="seccontent">
              {props.children}
            </div>
          </section>
          :
          <section className='mainhead'>
            <div className=" ">
              <span>{props.name}</span>
            </div>
            <div className="">
              {props.children}
            </div>
          </section>
      }
    </Wrapper>
  )
}

export default FilterItem;

const Wrapper = styled.div`
.filterhead{
    padding-bottom:12px;   
    text-transform:capitalize;
  }
.filterbox{
font-size:22px;
display:flex;
text-transform:capitalize;
width : 100%;
}
.mainhead{
  padding:16px;
  border-bottom : 1px solid gray;
  span {
    color : var(--clr-primary-3);
  }
}
.sectionlayout{
    display :flex;
    justify-content: space-between;
    align-items: center;
  }
  .sectionlayout span:first-child{
    font-size:16px;
    text-transform: capitalize;
  }
  .contentstyle{
    cursor: pointer;
    margin: -16px;
    padding: 16px;
  }
  .expanded {
    transform: rotate(180deg);
  }
  .hide{
    display : none;
  }
  .seccontent{
    margin : 7px 0;
  }

  .default{
    left :10rem;
  }


`