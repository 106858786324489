import React from 'react'
import styled from 'styled-components';
import '../../assets/css/button46.css';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import { device } from '../../Utils/device';

function ContentPanel(props) {

    return (
        <Wrapper>
            <div className='cpheader'>
                <p className='heading'>{props.heading}</p>
                <p className='tagline'>{props.tagline}</p>
            </div>
            <div className='content' >
                {props.children}
            </div>
            <div className='footer'>
                {
                    props.next ? <button className='button-46 cust-btn-46' onClick={() => props.changeTab(props.next)} >
                        <span className={'numberCircle'}>
                            <FontAwesomeIcon icon={faAnglesRight} />
                        </span>
                        Next Step </button>
                        : <></>
                }

            </div>
        </Wrapper>
    )
}

export default ContentPanel;

const Wrapper = styled.div`

    display:flex;
    flex-direction:column;
    height: 100%;
    border: 2px solid var(--clr-grey-10);
    /* box-shadow: rgba(100, 100, 111, 0.3) 0px 7px px 0px; */

    .cpheader{
        /* padding:1rem; */
        text-align:left;
        .heading{
            font-size:20px;  
            padding:1rem;
            margin:-1px;
            color : var(--clr-white);
            background-color : var(--clr-primary-5);
            box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;    
        }
        .tagline{
            margin:0px;
            font-size:14px;
            padding:15px;
          
            font-style:italic;
            background-color : var(--clr-grey-10);     
        }
    }
 

    .footer{
        padding:0 1rem 2rem;
        display:flex;
        flex-direction:row;
        width:800px;
    }
    .content{
        padding:1.5rem 0 0 1rem;
        width:80%;
        min-height:400px;
        input, textarea , .gran , .gran label{
            font-size:14px;
            border-radius: 5px;
            border-width: thin;
            padding:6px;
            outline: none;
            &:hover{        
                outline-color : var(--clr-primary-3);
            }
        }
    }
    .control-section{
        font-size :14px;
        padding-bottom:2rem;
        > label{
            text-align : left !important;
            display: block;
        }
        }
    .lbl-for-tb {
        display: block;
        font-size:14px;
        color:var(--clr-primary-3); 
        margin-bottom:0.25rem;
        text-align:left;    
    }
    .txtbox{
        width:40rem;  
        padding: 0.5rem;
        font-size: 18px;
    }

    .control-section{
    text-align:left;
    padding-bottom : 2rem; 
    }

    .btnform{
            width:fit-content;   
            color: black;
            font-style:normal;
            background: var(--clr-primary-5);
            border: 1px solid transparent;
            border-radius: 4px;
            display: inline-block;
            height: fit-content;
            text-align:center;            
            margin: 0px;
            
            padding: 0.7rem 3rem;
            transition: .2s all ease-in-out;
            cursor:pointer;
            &:hover{
                background-color :var(--clr-primary-4);
            }
            &:active{
                background-color :var(--clr-primary-5);
            }
    }


    label.mandatory::after{
        content: '*';
        color:red;
        }
    .numberCircle {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 4px 8px;
    margin-right:10px;
    background: var(--clr-primary-4);
    border: 2px solid var(--clr-white);
    color: var(--clr-white);
    text-align: center;
    }

    .cust-btn-46{
        padding:5px;
        border-radius:5px;
        font-size:18px;
    }

    @media screen and  (${device.tablet}) {
        .cpheader{   
            .heading{
                font-size:34px;           
            }
            .tagline{
                margin:0px;
                font-size:18px; 
            }
        }
        .txtbox{
            width:90vw;  
            padding: 0.5rem;
            font-size: 18px;
        }
        .control-section{
        font-size :14px;
        padding-bottom:1.5rem;
            width:90vw;
        }
  }

  @media screen and  (${device.mobileL}){
 
  }
`
