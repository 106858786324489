import React from 'react'
import styled from 'styled-components'

function SimpleFooter() {

  return (
    <Wrapper>
      <div className=" text-center p-2 section-c" >
        @2024 Quation Solutions Private Limited. All rights reserved.
      </div>
    </Wrapper>
  )
}

export default SimpleFooter;

const Wrapper = styled.footer`
 .section-c{
  background-color:var(--clr-grey-10);
 }
`