import React , { useEffect , useState } from 'react';
import '../../assets/css/animate-on-scroll.css'

function AnimateOnScroll({ offset = 10, children}) {
    const [animatedElements, setAnimatedElements] = useState([]);
  
    const windowTop = (offset * window.innerHeight) / 100;
    const windowBottom = window.innerHeight - windowTop;
  
    const startAnimation = (element) => {
      element.style.animationDelay = element.dataset.animationDelay;
      element.style.animationDuration = element.dataset.animationDuration;
      element.classList.add(element.dataset.animation);
      element.dataset.animated = 'true';
    };
  
    const isInViewport = (element) => {
      const elementRect = element.getBoundingClientRect();
      const elementTop =
        elementRect.top + parseInt(element.dataset.animationOffset) || elementRect.top;
      const elementBottom =
        elementRect.bottom - parseInt(element.dataset.animationOffset) ||
        elementRect.bottom;
  
      return (
        elementTop <= windowBottom &&
        elementBottom >= windowTop
      );
    };
  
    const handleScroll = () => {
      animatedElements.forEach((el) => {
        if (!el.dataset.animated && isInViewport(el)) {
          startAnimation(el);
        }
      });
    };
  
    const updateElements = () => {
      const elementsToAnimate = document.querySelectorAll(
        "[data-animation]:not([data-animated])"
      );
      //setAnimatedElements(Array.from(elementsToAnimate));
      setAnimatedElements((prevElements) => [
        ...prevElements,
        ...Array.from(elementsToAnimate),
      ]);
    };
  
    useEffect(() => {
      const elementsToAnimate = document.querySelectorAll(
        "[data-animation]:not([data-animated])"
      );
  
      elementsToAnimate.forEach((element) => {
        if (isInViewport(element)) {
          startAnimation(element);
        }
      });
  
      const handleScroll = () => {
        elementsToAnimate.forEach((element) => {
          if (!element.dataset.animated && isInViewport(element)) {
            startAnimation(element);
          }
        });
      };
  
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', updateElements);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', updateElements);
      };
    },[animatedElements]);
  
    return (
        <>{children}</>
    );
  };

export default AnimateOnScroll;
