import React from 'react'
import styled from 'styled-components';
import Plot from 'react-plotly.js';
import ErrorMetrics from './ErrorMetrics';
import { GetTracesFromTSGraphData , ModelsList} from './ScriptForGraph';


export default function GraphicalViewContainer({ data }) {

  var selectorOptions = {
    buttons: [{
        step: 'month',
        stepmode: 'backward',
        count: 1,
        label: '1m'
    }, {
        step: 'month',
        stepmode: 'backward',
        count: 6,
        label: '6m'
    }, {
        step: 'year',
        stepmode: 'todate',
        count: 1,
        label: 'YTD'
    }, {
        step: 'year',
        stepmode: 'backward',
        count: 1,
        label: '1y'
    }, {
        step: 'all',
    }],
};
 
  if (data?.ts_graph) {
   
    let formattedObj = {
      actual: {
        dates: [],
        demands: []
    },
      forecast: {},
      prediction: {}
     }

     let arr = GetTracesFromTSGraphData(data?.ts_graph, formattedObj);
     let models = ModelsList(data?.ts_graph);
   

    return (
      <Wrapper>

      <div class='container' >
      <h1>Graphical View</h1>
        <div className='graph'>
          <Plot
            data={arr}
            layout={
              {
                width:1200,
                height: 750,
                title: `Prediction and Forecasting Line Graph for Models ${models}`,
                xaxis: {  title: "Dates" , rangeselector: selectorOptions, rangeslider: {} },
                yaxis: { title: "Demand", fixedrange: true },
                showlegend: true,
                
                legend: {
                  xanchor: "center",
                  yanchor: "top",
                  orientation:"h",
                  
                  y: -0.5, // play with it
                  x: 0.5   // play with it
                }
              }
            }
          />
        </div>
        <div>
        
        </div>
        </div>
      </Wrapper>)
  } else {
    return null;
  }

}


const Wrapper = styled.div`
.container{
    display:flex;
    flex-direction:column;
    justify-content: space-around;
}
  .graph{
    overflow:auto;
    width:100%;
  }
  .cust-table{
    width:fit-content;
  }
`