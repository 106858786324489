import React , { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { device } from '../../Utils/device';

function SiteBreadCrumb() {
  const location = useLocation();
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }

  })

  function renderSwitch(param) {
    param = decodeURI(param);
    let paramKeys = param.slice(1).split('/')
    switch (paramKeys[0]) {
      case 'wf':
        return <>
          <Link to="/wflist" >Workflows</Link>
          <span>{paramKeys[1]}</span>
        </>;
      case 'wflist':
        return <span>Workflows</span>;
      case 'wfcreate':
        return <span>New Workflow</span>;
      default:
        return '';
    }
  }

  return (
    <Wrapper>
      <div className={`bcContainer page-width ${isSticky ? "sticky" : ""}`} >
        <Link className='homelink' to="/" >Home</Link>
        {renderSwitch(location.pathname)}
      </div>
    </Wrapper>
  )
}

export default SiteBreadCrumb;

const Wrapper = styled.div`
.bcContainer{
  border-bottom:1px solid black;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, var(--clr-primary-3) 100%);
  margin : 0 auto;
  height : fit-content;
  .homelink{
    color:var(--clr-primary-5);
    &:hover{
      color:var(--clr-primary-3);
    }
  }
  span{
    color:var(--clr-primary-5);
  
  }
  span, a{
    font-size:20px;
  }
  a{
    text-decoration:none;    
    ::after{
      content :"   \\002F   ";
      display : inline;
      color:var(--clr-primary-5);;
    }
  }
  a:last-child{
    ::after{
      content:""
    }
  }
}

.sticky{
  position:fixed;
  top:0;
  left:0;
  background-color: white;
  z-index: 10;
}

@media screen and (${device.tablet}) {
       
}

@media screen and (${device.mobileL}) {

}

`