import React, { useState, useEffect } from 'react'
import { getName } from '../../Utils/filterScripts'

function SelectedFilters({ selectedData, eNumObject, mObject }) {

  return (
    <div>
      <ul>
        {selectedData.filter(elem => elem.leafnode === true && elem.checked === true).length > 0 ? selectedData
          .filter(elem => elem.leafnode === true && elem.checked === true)
          .map(elem => (<li key={elem._id} > {getName(elem._id, mObject, eNumObject)} </li>))
          : <li>Not Selected</li>
        }
      </ul>
    </div>
  )
}

export default SelectedFilters;
