import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { device } from '../../Utils/device';

export default function Card({ ImageSource, Name, Description, Animation, AnimationDelay = '0s' }) {

  const [flag, setFlag] = useState(true);

  const handleOnClick = () => {
    setFlag(!flag);
  }

  useEffect(() => {

  })
  return (
    <Wrapper>
      <div class="flip-card" data-animation={Animation} data-animation-delay={AnimationDelay} >
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <img src={ImageSource} alt="Avatar" />
            <p>{Name}</p>
          </div>
          <div class="flip-card-back">
            <p>{Description}</p>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`.flip-card {
    background-color: white;
    width: 300px;
    height: 300px;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    border:1px solid grey;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: #fff;
    color: black;
    img{
        width:99%;
        margin-top:1px;
        height:80%;
        object-fit:contain;
    }
    p{
        font-size:28px;
    }
  }
  
  .flip-card-back {
    background-color: #fff;
    color: black;
    padding:5% 1rem;
    font-size:1.3rem;

    transform: rotateY(180deg);
  }

  @media screen and  (${device.tablet}) {  
    .flip-card {
    background-color: white;
    width: 300px;
    height: 200px;
    perspective: 1000px;
  }
  
    .flip-card-front {
    img{
        width:80%;      
        height:70%;
    }
    p{
        font-size:18px;
    }
  }

  .flip-card-back {
    background-color: #fff;
    color: black;
    padding:5% 1rem;
    font-size:1rem;
  }
  }

  @media screen and  (${device.mobileL}) {  

    .flip-card {
    background-color: white;
    width: 300px;
    height: 200px;
    perspective: 1000px;
  }
  
    .flip-card-front {
    img{
        width:80%;      
        height:70%;
    }
    p{
        font-size:18px;
    }
  }

  .flip-card-back {
    background-color: #fff;
    color: black;
    padding:5% 1rem;
    font-size:1rem;
  }

  }

`