import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { isEmpty } from '../../Utils/helper'

function SectionD2(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const init = async () => {
      let form = JSON.parse(sessionStorage.getItem('form'));
      setData(form.combinations);  
    }
    init();
  }
    , [])

   
    if(isEmpty(data)){
      return false;
    }
    
  return (
    <Wrapper>
      {
        
        !isEmpty(data)?
          <div className='f1'>
            <label>Selected Aggregate levels</label>
            <div className='main'>
              <table className="table cust-table">
                <thead>
                  <tr>
                    <th scope="col">Combination</th>
                    <th scope="col">Trained</th>
                    <th scope="col">Trained Thershold</th>
                    <th scope="col">Test </th>
                    <th scope="col">Test Thershold</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    isEmpty(data) ?
                      <></> :
                      <>
                        {
                        data?.map((elem, index) => (<tr key={index}>
                          <td>                           
                              {
                                elem?.element?.map((el, index) => (<span key={index} className={el.group} >{el.name}</span>))
                              }                           
                          </td>
                          <td>{elem.train}</td>
                          <td>{elem.train_th}</td>
                          <td>{elem.test}</td>
                          <td>{elem.test_th}</td>                   
                        </tr>))
                        }
                      </>
                  }
                </tbody>
              </table>
            </div>
          </div>
          :
          <label>please select the business criteria configurations</label>
      }
    </Wrapper>
  )
}

export default SectionD2;

const Wrapper = styled.div`
.main{
  overflow:auto;
  max-height:20rem;
  
}
.f1{
  display : flex;
  flex-direction:column;
}

label{
  text-align: left;
}
ol{
  list-style-type:none;
  list-style-position: outside;
  padding-left:3rem;
}

.list{  
  display : flex;
  flex-direction:column;
  border:0.5px solid var(--clr-grey-8);
  border-radius:var(--radius);
  ul{
  display : flex;
  flex-direction:row;
  li{
    padding-right : 1rem;
    font-size:14px;
  }
  li:not(:last-child)::after{
    content : '>';
    padding-left : 1rem;
  }  
  }  
}

td span:not(:last-child)::after{
  content: ' > '
}
.group-a {
  color :var(--group-a);
}
.group-b {
  color :var(--group-b);
}
`