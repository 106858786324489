import React, { useState, useEffect } from 'react'
import { redirect } from "react-router-dom"
import styled from 'styled-components'
import { getLocalObject } from '../../Utils/helper'
import filterEnums from '../../Utils/enums'

import { getSessionObject, displayMessage, setValue } from '../../Utils/helper'
import HttpService from '../../services/HttpService'


function WFFormValidateModal({ onClose }) {

  const [invalidCombinations, setInvalidCombinations] = useState([]);

  useEffect(() => {
    setInvalidCombinations(invalidCombinations => invalidCombinations = getInvalidCombinationList());
  }, [])



  function getInvalidCombinationList() {

    let form = getSessionObject('form');
    if (form) {
      return form.invalid_combinations;
    }
    else {
      return [];
    }
  }

  const btnProceedHandler = () => {
    setValue('session', 'form', 'ignore_invalid_combinations', true);
    let form = getSessionObject('form');
    let respObj = wfCreateRequest(form);

    if (respObj.hasOwnProperty('acknowledgement')) {
      displayMessage("success", "Workflow", respObj.acknowledgement);
      redirect("/wflist");
    } else {
      if (respObj.hasOwnProperty('error')) {
        displayMessage("danger", "error", respObj.error);
      }
      else {
        displayMessage("danger", "error", "interal error");
      }
    }
  }

  async function wfCreateRequest(requestParam) {
    const axiosClient = HttpService.getAxiosClient();
    const url = `${process.env.REACT_APP_API_BASE_URL}/workflow_form`;
    const config = {
      headers: {
        Accept: "application/json"
      }
    };
    const data = requestParam;
    let responseData = await axiosClient.post(url, data, config)
      .then(res => res = res.data)
      .catch(function (err) {
        return { error: err };
      })
    return responseData;
  }

  if (!invalidCombinations) { return false }
  return (
    <Wrapper>
      <div className="mdal">
        <div className="header">
          <h3>NOTICE</h3>
        </div>
        <div className="content">

          <p>Invalid combinations of filters found under business configuration section.
            System could not able to train the models for below combinations of selections. </p>

          <div className='list' >
            {/* {invalidCombinations.length > 0 ? invalidCombinations.map(elem => (<Span list={Object.entries(elem)} />)) : <>Invalid Combinations Not found</>} */}
            {invalidCombinations.length > 0 ? invalidCombinations.map(elem => ((<Span list={Object.entries(elem)} />))) : <>Invalid Combinations Not found</>}

          </div>
          <p>
            Click on <strong>Back</strong> button and modify the options and resumbit the form
            <br /> Click on <strong>Proceed</strong> to ignore Invalid combinations while training the model</p>
        </div>
        <div className="footer">
          <button className='fcBtn' onClick={onClose}>Back</button>
          <button className='fcBtn' onClick={btnProceedHandler}>Proceed</button>
        </div>


      </div>
    </Wrapper>
  )
}

export default WFFormValidateModal

function Span({ list }) {

  return (
    <div>
      {list.map((elm, index) => (<span key={index} > {getNameFromID(elm[0], elm[1])}</span>))
      }
    </div>
  )
}

function getNameFromID(key, val) {
  let mObject = {}
  let keyName = ''
  switch (key) {
    case 'state_id': keyName = 'state';
      mObject = getLocalObject(filterEnums.states.name);
      break;
    case 'store_id': keyName = 'store'; mObject = getLocalObject(filterEnums.stores.name); break;
    case 'cat_id': keyName = 'category'; mObject = getLocalObject(filterEnums.categories.name); break;
    case 'dept_id': keyName = 'department'; mObject = getLocalObject(filterEnums.departments.name); break;
    case 'item_id': keyName = 'products'; mObject = getLocalObject(filterEnums.products.name); break;
    default: break;
  }
  return mObject[val];
}

const Wrapper = styled.div`
.mdal {
    display: flex;  
    flex-direction : column;
    justify-content : space-between;  
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
    background-color: white;
    border: 2px solid var(--clr-primary-3);
    border-radius: 12px;
    position:  fixed;
    width: 50vw;
    height:fit-content;
    top: 25%;
    left: 25%; 

    .content {
        padding: 0 2rem;

        div.list {
          margin-bottom : 1.5rem;
          height:8rem;
          overflow:auto;
          div {
            margin: 0 auto;
            display: flex;
            justify-content: flex-start;

            span {
              margin: 0.2rem;
              background-color: var(--clr-primary-10);
              border: 1px solid var(--clr-primary-3);
              color: var(--clr-primary-3);
              border-radius:0.2rem;
              padding:0.2rem;
            }
          }
        }
      }
 
  }
  
  div.footer{
    display : flex;
    flex-direction : row;
    width : 100%;
    justify-content : space-around;
  }  
`




// Invalid combinations of filters found under business configuration section.
// System could not able to train the models for below combinations of selections.
// You can either click on 'Back' button and modify the options and resumbit the form
// or you can click on 'Proceed' where invalid combinations gets ignored to train the model.