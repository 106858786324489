import { Card1, Card2, Card3, Card4, Card5, Card6, Card7, Card8 } from '../assets'
import { faUserPlus , faSignIn , faSignOutAlt , faUser } from '@fortawesome/free-solid-svg-icons'

const Cards = [
    {
        "ImageSource": Card1,
        "Name": "Revenue Planning",
        "Description": "Sales forecasting enables businesses to anticipate future sales volumes, allowing for more accurate revenue planning. This is crucial for budgeting and financial management.",
        "Animation" : 'slideInUp',
        "AnimationDelay" : '.5s'
    }, {
        "ImageSource": Card2,
        "Name": "Resource Allocation",
        "Description": "With a clear understanding of future sales expectations, businesses can allocate resources more efficiently. This includes staffing, inventory, marketing budgets, and production capacity.",
        "Animation" : 'slideInUp',
        "AnimationDelay" : '.6s'
    }, {
        "ImageSource": Card3,
        "Name": "Cash Flow Management",
        "Description": "By predicting sales cycles and revenue streams, businesses can better manage their cash flow. This is especially important for ensuring there is enough liquidity to cover operational expenses.",
        "Animation" : 'slideInUp',
        "AnimationDelay" : '.7s'
    }, {
        "ImageSource": Card4,
        "Name": "Marketing Strategy",
        "Description": "Forecasting provides insights into which products or services are likely to perform well. This information can guide marketing strategies and promotional activities, optimizing efforts for maximum impact.",
        "Animation" : 'slideInUp',
        "AnimationDelay" : '.8s'
    }, {
        "ImageSource": Card5,
        "Name": "Risk Mitigation",
        "Description": "Businesses can identify potential risks and challenges early on through sales forecasting. This proactive approach allows for the development of contingency plans and strategies to mitigate risks.",
        "Animation" : 'slideInUp',
        "AnimationDelay" : '.9s'
    }, {
        "ImageSource": Card6,
        "Name": "Setting Realistic Targets",
        "Description": "Sales forecasts help in setting realistic and achievable sales targets. This can motivate sales teams and provide a benchmark for performance evaluation.",
        "Animation" : 'slideInUp',
        "AnimationDelay" : '1s'
    }
]

const Cards2 = [
    {
        "Icon" : {faUserPlus},
        "Heading" : "Integrated with Postgres Database",
        "Content" : "Seamless integration with Postgres database for secure, efficient, and scalable data storage."
    },
    {
        "Icon" :  {faUserPlus},
        "Heading" : "Data Selection",
        "Content" : "Intuitively select training and validation time periods to tailor the forecasting engine to your unique needs."
    },
    {
        "Icon" : {faUserPlus},
        "Heading" : "Data Validation",
        "Content" : "Ensure data integrity with dynamic validation checks, ensuring sufficient data points for each product-date-store or hierarchical combination."
    },{
        "Icon" :  {faUserPlus},
        "Heading" : "Granularity Selection",
        "Content" : "Choose your preferred granularity—whether daily, weekly, or monthly—with automatic adaptation to available data points for accurate forecasts."
    },
    {
        "Icon" : {faUserPlus},
        "Heading" : "Feature Engineering",
        "Content" : "Elevate your forecasting accuracy with advanced feature engineering capabilities for a more nuanced analysis."
    },
    {
        "Icon" :  {faUserPlus},
        "Heading" : "Automatic Feature Selection",
        "Content" : "Let our engine do the heavy lifting! Automatically select relevant features to streamline your forecasting process."
    }
    ,
    {
        "Icon" : {faUserPlus},
        "Heading" : "Model Selection",
        "Content" : "Harness the power of diverse models including ARIMA, TBAT, NBEATS, LightGBM, XGBoost, CatBoost, Holt Winters, TSLM, and more."
    },
    {
        "Icon" :  {faUserPlus},
        "Heading" : "Deploy as Workflows",
        "Content" : "Seamlessly deploy selected models as workflows, creating a smooth and efficient forecasting pipeline."
    },
    {
        "Icon" : {faUserPlus},
        "Heading" : "Schedule Refit or Forecasting",
        "Content" : "Schedule refit or forecasting tasks to ensure your models stay up-to-date and aligned with evolving data patterns."
    },
    {
        "Icon" :  {faUserPlus},
        "Heading" : "Evaluation Metrics",
        "Content" : "Evaluate performance with key metrics like RMSE, MAPE, and MAE for training, validation, and moving test datasets in production."
    },
    {
        "Icon" :  {faUserPlus},
        "Heading" : "Auto-refresh Test Metrics",
        "Content" : "Stay informed with auto-refreshed test metrics, providing real-time insights into model performance during production."
    }
]

export { Cards , Cards2};

