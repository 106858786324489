import React from "react";
import Button from 'react-bootstrap/Button';
import { newFormCreate } from "../../pages/wfCreate";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'


class MenuTabs extends React.Component {
  render() {
    let content;
    let buttons = [];
    return (
      <div id={this.props.name} className={this.props.style.tabs}>
        {React.Children.map(this.props.children, child => {
          buttons.push({label : child.props.label, name : child.props.name})
          if (child.props.label === this.props.tabState.activeTab) content = child.props.children
        })}

        <TabButtons 
          activeTab={this.props.tabState.activeTab} 
          buttons={buttons} 
          changeTab={this.props.changeHandler} 
          style={this.props.style} 
        />

        <div  
            id="tabContent" 
            className={this.props.style['tab-content']}>        
          {content}
        </div>

      </div>
    );
  }
}

const TabButtons = ({ buttons, changeTab, activeTab,style }) => {

  const btnResetOnClickHandler = () => {
    if(window.confirm("Reset clears all the entered form inputs. Are you sure to proceed with reset?")){
      newFormCreate();
      changeTab('menu1')
    }
  }

  return (
    <div className={`tab-buttons ${style['tab-buttons']}`}>
      {buttons.map((button,index) => {
        return ( <div   key={button.label} >
         
        <button 
                
                  id={button.label} 
                  className={button.label === activeTab ? style['active'] : ''} 
                  onClick={() => changeTab(button.label)}><span>
                  <span className={style['numberCircle']}>{index+1}</span>   
                  <span className={style['menu-name']}> {button.name}</span>
                  </span>
                  <span className={style["arrow-right"]}><FontAwesomeIcon icon={faAngleRight} /></span>
                </button>
               
                </div>)
      })}
      <div>
      <Button className={style['btn-reset']} onClick={btnResetOnClickHandler} >Reset</Button>
      </div>     
    </div>
  )
}

const MenuTab = props => {
  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}

 

export { MenuTabs, MenuTab }

