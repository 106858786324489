import filterEnums from "./enums";


//group Products ByCategory And Deparment
const groupLevels = (flatList, levelNumber, listCode) => {
  let arrList = flatList.reduce((acc, currentValue) => {
    let l1_id_value = currentValue['l1_id'];
    let l2_id_value = currentValue['l2_id'];
    let l3_id_value = currentValue['l3_id'];

    const tagListCode = (a) => { return `${listCode}${a.substring(1, 2)}-${currentValue[a]}` }

    if (!acc[l1_id_value]) {
      if (levelNumber === 1) {
        acc[l1_id_value] = { l1_id: tagListCode('l1_id'), l1_name: currentValue['l1_name'] };
      }
      if (levelNumber > 1) {
        acc[l1_id_value] = { l1_id: tagListCode('l1_id'), l1_name: currentValue['l1_name'], l2_list: {} };
      }
    }

    if (levelNumber === 2 && !acc[l1_id_value].l2_list[l2_id_value]) {
      acc[l1_id_value].l2_list[l2_id_value] = ({ l2_id: tagListCode('l2_id'), l2_name: currentValue['l2_name'] })
    }
    if (levelNumber > 2 && !acc[l1_id_value].l2_list[l2_id_value]) {
      acc[l1_id_value].l2_list[l2_id_value] = ({ l2_id: tagListCode('l2_id'), l2_name: currentValue['l2_name'], l3_list: {} })
    }

    if (levelNumber === 3 && !acc[l1_id_value].l2_list[l2_id_value].l3_list[l3_id_value]) {
      acc[l1_id_value].l2_list[l2_id_value].l3_list[l3_id_value] = ({ l3_id: tagListCode('l3_id'), l3_name: currentValue['l3_name'] })
    }

    return acc;

  }, {});

  if (levelNumber > 0) {
    arrList = Object.values(arrList);
    if (levelNumber > 1) {
      arrList.forEach(elem => { elem.l2_list = Object.values(elem.l2_list) });
      if (levelNumber > 2) {
        arrList.forEach(elem => {
          elem.l2_list = Object.values(elem.l2_list);
          elem.l2_list.forEach(elem2 => elem2.l3_list = Object.values(elem2.l3_list))
        });
      }
    }
  }
  return arrList;
};

// category and department of product filter functions [begin]
function appendCategoryDepartmentNames(mProducts, mDepartment, mCategory) {
  mProducts.forEach(product => {
    product.department_name = (mDepartment.find(department => department.id === product.department_id)).department_code;
    product.category_name = (mCategory.find(category => category.id === product.category_id)).category_code;
  });
  return mProducts;
}

function addStateName(storeObj, stateObj) {
  storeObj.forEach((elem) => {
    elem.stateName = (stateObj.find(state => state.id === elem.state_id)).state_name;
  })
  return storeObj;
}

function ChangeStoreFieldNames(arrList) {
  arrList = arrList.map(element => {
    const { state_id: l1_id,
      id: l2_id,
      stateName: l1_name,
      store_code: l2_name,
      ...otherProps } = element;
    const newElement = { l1_id, l2_id, l1_name, l2_name, ...otherProps }
    return newElement;
  });
  return arrList;
}

function getStateList(arrList) {
  arrList = arrList.map(element => {
    const { id: l1_id,
      state_name: l1_name,
      ...otherProps } = element;
    const newElement = { l1_id, l1_name, ...otherProps }
    return newElement;
  });
  moveToLocal(filterEnums.states, arrList);
  let result = groupLevels(arrList, filterEnums.states.levelNumber, filterEnums.states.listcode);
  return result;
}

function getStoreList(storeList, stateList) {
  let arrList = addStateName(storeList, stateList)
  arrList = ChangeStoreFieldNames(arrList)
  moveToLocal(filterEnums.stores, arrList);
  let result = groupLevels(arrList, filterEnums.stores.levelNumber, filterEnums.stores.listcode)
  return result;
}

function getCategoryList(arrList) {
  arrList = arrList.map(element => {
    const { id: l1_id,
      category_code: l1_name,
      ...otherProps } = element;
    const newElement = { l1_id, l1_name, ...otherProps }
    return newElement;
  });
  moveToLocal(filterEnums.categories, arrList);
  let result = groupLevels(arrList, filterEnums.categories.levelNumber, filterEnums.categories.listcode)
  return result;
}

function getModelList() {
  let arrList = [{
    "l1_id": "1",
    "l1_name": "ARIMA"
  },
  {
    "l1_id": "2",
    "l1_name": "ModelB"
  },
  {
    "l1_id": "3",
    "l1_name": "ModelC"
  }
  ]
  moveToLocal(filterEnums.models, arrList);
  let result = groupLevels(arrList, filterEnums.models.levelNumber, filterEnums.models.listcode)
  return result;
}

const mergeArraysById = (array1, prop1, array2, prop2) =>
   array1.map(a1Elem => ({
    ...array2.find((a2Elem) => (a2Elem[prop2] === a1Elem[prop1]) && a2Elem.category_code),
    ...a1Elem
  }));

function getDepartmentList(departments, categories) {
  let arrList = mergeArraysById(departments, 'category_id', categories, 'id');
  arrList = arrList.map(element => {
    const { category_id: l1_id,
      category_code: l1_name,
      id: l2_id,
      department_code: l2_name,
      ...otherProps } = element;
    const newElement = { l1_id, l2_id, l1_name, l2_name, ...otherProps }
    return newElement;
  });
  moveToLocal(filterEnums.departments, arrList);
  let result = groupLevels(arrList, filterEnums.departments.levelNumber, filterEnums.departments.listcode)
  return result;
}


function getProductsList(products, departments, categories) {
  var arrList = appendCategoryDepartmentNames(products, departments, categories);

  arrList = arrList.map(element => {
    const { category_id: l1_id,
      department_id: l2_id,
      id: l3_id,
      category_name: l1_name,
      department_name: l2_name,
      item_code: l3_name,
      ...otherProps } = element;
    const newElement = { l1_id, l2_id, l3_id, l1_name, l2_name, l3_name, ...otherProps }
    return newElement;
  });
  moveToLocal(filterEnums.products, arrList);
  let result = groupLevels(arrList, filterEnums.products.levelNumber, filterEnums.products.listcode)
  return result;
}

function moveToLocal(eNumObject, arrList) {
  let obj = localStorage.getItem(eNumObject.name);
  if (!obj) {
    let newObj = {}
    switch (eNumObject.levelNumber) {
      case 1: arrList.map(elem => newObj[elem.l1_id] = elem.l1_name); break;
      case 2: arrList.map(elem => newObj[elem.l2_id] = elem.l2_name); break;
      case 3: arrList.map(elem => newObj[elem.l3_id] = elem.l3_name); break;
      default: break;
    }
    let newObjStr = JSON.stringify(newObj);
    localStorage.setItem(eNumObject.name, newObjStr);
  }
}

const groupBy = (input, key) => {
  return input.reduce((acc, currentValue) => {
    let groupKey = currentValue[key];
    if (!acc[groupKey]) {
      acc[groupKey] = { state_id: currentValue['state_id'], state_name: currentValue['stateName'], stores: [] };
    }
    acc[groupKey].stores.push({ store_id: currentValue['id'], store_name: currentValue['store_code'] });
    return acc;
  }, {});
};

const getName = (id, mObject, eNumObject) => {
  let obj = {};

  switch (eNumObject.levelNumber) {
    case 1: mObject.map(elem => obj[elem.l1_id] = elem.l1_name);
      break;
    case 2: mObject.map(elem => elem.l2_list
      .map(elem1 => obj[elem1.l2_id] = elem1.l2_name))
      break;
    case 3: mObject.map(elem => elem.l2_list
      .map(elem1 => elem1.l3_list
        .map(elem2 => obj[elem2.l3_id] = elem2.l3_name)))
      break;
    default: break;
  }
  return obj[id];

}

const levelNumber = (str,listcode) => {  
  let a = str.replace(listcode,'');
  let b = a.split('-')[0];
  return Number(b);   
}

export {
  addStateName, 
  ChangeStoreFieldNames, 
  groupLevels, 
  getStateList, 
  groupBy,
  getStoreList, 
  getCategoryList, 
  getDepartmentList, 
  getProductsList, 
  getModelList, 
  getName, 
  levelNumber
}