import React, { useEffect } from 'react'
import styled from 'styled-components';
import { levelNumber } from '../../Utils/filterScripts';

const MultiLevelCbControl = (function (props) {

  const changeHandler = (e) => {
    props.changeHandler(props.filterEnum, e) //https://www.freecodecamp.org/news/why-arrow-functions-and-bind-in-reacts-render-are-problematic-f1c08b060e36
  }

  useEffect(() => {
    let elems = props.selections.filter(elem => elem.indeterminate === true);
    for (let elem of elems) {
      document.getElementById(elem._id).indeterminate = elem.indeterminate;
    }
  }, [props.selections]);

   const getId = (taggedId) => { return taggedId.split('-')[1] };
  return (
    <Wrapper className={`${props.filterEnum.name} , ${props.filterEnum.group}`}>
      {
        props?.data !== null && props.data.length > 0 ?
          <ul className='filter-item level-1'>
            {props.data.map((l1item, l1Index) =>
              <React.Fragment key={l1item.l1_id}>
                <li key={l1item.l1_id} id={`li-${l1item.l1_id}`}>
                  <input
                    type="checkbox"
                    checked={props.selections?.find(elem => elem._id === l1item.l1_id) === undefined ? false : props.selections.find(elem => elem._id === l1item.l1_id).checked}
                    indeterminate={props.selections.find(elem => elem._id === l1item.l1_id)?.indeterminate ? 1 : 0}
                    name={l1item.l1_id}
                    id={l1item.l1_id}
                    data-id={getId(l1item.l1_id)}
                    data-parent={0}
                    data-levelnumber={levelNumber(l1item.l1_id, props.filterEnum.listcode)}
                    data-group={props.filterEnum.group}
                    data-name={l1item.l1_name}
                    data-type={props.filterEnum.listcode}
                    onChange={changeHandler}
                  />
                  <label htmlFor={l1item.l1_id} >{l1item.l1_name}</label>
                  {
                    l1item.l2_list?.length > 0 ?
                      <>
                        <ul className='filter-item level-2'> {
                          l1item.l2_list.map((l2item, l2Index) =>
                            <React.Fragment key={l2item.l2_id}>
                              <li key={l2item.l2_id} id={`li-${l2item.l2_id}`} >
                                <input

                                  checked={props.selections.find(elem => elem._id === l2item.l2_id) === undefined ? false : props.selections.find(elem => elem._id === l2item.l2_id).checked}
                                  indeterminate={props.selections.find(elem => elem._id === l2item.l2_id)?.indeterminate ? 1 : 0}
                                  type="checkbox"
                                  name={l2item.l2_id}
                                  id={l2item.l2_id}
                                  data-id={getId(l2item.l2_id)}
                                  data-parent={getId(l1item.l1_id)}
                                  data-levelnumber={levelNumber(l2item.l2_id, props.filterEnum.listcode)}
                                  data-group={props.filterEnum.group}
                                  data-name={l2item.l2_name}
                                  data-type={props.filterEnum.listcode}
                                  onChange={changeHandler}
                                />
                                <label htmlFor={l2item.l2_id}>{l2item.l2_name}</label>
                                <ul className='filter-item level-3'>
                                  {
                                    l2item.l3_list?.length > 0 ? l2item.l3_list.map((l3item, l3Index) =>
                                      <React.Fragment key={l3item.l3_id}>
                                        <li key={l3item.l3_id} >
                                          <input

                                            checked={props.selections.find(elem => elem._id === l3item.l3_id) === undefined ? false : props.selections.find(elem => elem._id === l3item.l3_id).checked}
                                            indeterminate={props.selections.find(elem => elem._id === l3item.l3_id)?.indeterminate ? 1 : 0}
                                            type="checkbox"
                                            name={l3item.l3_id}
                                            id={l3item.l3_id}
                                            data-id={getId(l3item.l3_id)}
                                            data-parent={getId(l2item.l2_id)}
                                            data-levelnumber={levelNumber(l3item.l3_id, props.filterEnum.listcode)}
                                            data-group={props.filterEnum.group}
                                            data-name={l3item.l3_name}
                                            data-type={props.filterEnum.listcode}
                                            onChange={changeHandler}
                                          />
                                          <label htmlFor={l3item.l3_id}>{l3item.l3_name}</label>
                                        </li>
                                      </React.Fragment>
                                    )
                                      : ''
                                  }
                                </ul>
                              </li>
                            </React.Fragment>
                          )}
                        </ul>
                      </>
                      : ''
                  }

                </li>
              </React.Fragment>
            )}
          </ul>
          : <></>
      }
    </Wrapper>
  )
})

export default MultiLevelCbControl;

const Wrapper = styled.div`
overflow:auto;
max-height:20rem;
font-size:14px;
.level-1{
  display : flex;
  flex-direction : row;
  justify-content:flex-start;
  gap: 4rem;
}
.level-3{

}
label{
  padding:3px;
   margin:2px;
}
`