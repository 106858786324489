
const traceValues = [
    {
        ts_type: 'forecast',
        ts_model: 'arima',
        ts_props: {
            type: 'scatter',
            name: `arima forecast`,
            mode: 'lines',
            marker: { color: 'orange' }
        },

    },
    {
        ts_type: 'forecast',
        ts_model: 'ar',
        ts_props: {
            type: 'scatter',
            name: 'AR Forecast',
            mode: 'lines',
            marker: { color: 'green' },
        }
    },
    {
        ts_type: 'forecast',
        ts_model: 'tbat',
        ts_props: {
            type: 'scatter',
            name: 'TBAT Forecast',
            mode: 'lines',
            marker: { color: 'pink' },
        }
    },
    {
        ts_type: 'forecast',
        ts_model: 'prophet',
        ts_props: {
            type: 'scatter',
            name: 'PROPHET Forecast',
            mode: 'lines',
            marker: { color: 'red' },
        }
    },
    {
        ts_type: 'forecast',
        ts_model: 'sarimax',
        ts_props: {
            type: 'scatter',
            name: 'SARIMAX Forecast',
            mode: 'lines',
            marker: { color: 'navy' },
        }
    },
    {
        ts_type: 'forecast',
        ts_model: 'holt-winters',
        ts_props: {
            type: 'scatter',
            name: 'Holt-winters Forecast',
            mode: 'lines',
            marker: { color: 'olive' },
        }
    },
    {
        ts_type: 'prediction',
        ts_model: 'arima',
        ts_props: {
            type: 'scatter',
            name: 'ARIMA Prediction',
            mode: 'lines+markers',
            marker: { color: 'orange' },
        },

    },
    {
        ts_type: 'prediction',
        ts_model: 'ar',
        ts_props: {
            type: 'scatter',
            name: 'AR Prediction',
            mode: 'lines+markers',
            marker: { color: 'green' },
        }

    },
    {
        ts_type: 'prediction',
        ts_model: 'tbat',
        ts_props: {
            type: 'scatter',
            name: 'TBAT Prediction',
            mode: 'lines+markers',
            marker: {
                color: 'green',
                line: {
                    color: 'pink',
                    width: 1,
                    dash: 'dot' // Set the dash style to 'dot' for a dotted line
                }
            },
        }
    },
    {
        ts_type: 'prediction',
        ts_model: 'prophet',
        ts_props: {
            type: 'scatter',
            name: 'PROPHET Prediction',
            mode: 'lines+markers',
            marker: {
                color: 'red',
                line: {
                    color: 'red',
                    width: 1,
                    dash: 'dot' // Set the dash style to 'dot' for a dotted line
                }
            },
        }
    },
    {
        ts_type: 'prediction',
        ts_model: 'sarimax',
        ts_props: {
            type: 'scatter',
            name: 'SARIMAX Prediction',
            mode: 'lines+markers',
            marker: {
                color: 'navy',
                line: {
                    color: 'navy',
                    width: 1,
                    dash: 'dot' // Set the dash style to 'dot' for a dotted line
                }
            },
        }
    },
    {
        ts_type: 'prediction',
        ts_model: 'holt-winters',
        ts_props: {
            type: 'scatter',
            name: 'HOLT-WINTERS Prediction',
            mode: 'lines+markers',
            marker: {
                color: 'olive',
                line: {
                    color: 'olive',
                    width: 1,
                    dash: 'dot' // Set the dash style to 'dot' for a dotted line
                }
            },
        }
    }
]

const emptyTimeSeriesObj = {
    dates: [],
    demands: []
}

function GetTracesFromTSGraphData(ts_graph, formattedObj) {
    let models = getModels(ts_graph);

    let convertedData =  JSON.parse(JSON.stringify(formattedObj));

    // Initialize forecast and prediction separately
    const tempObjForecast = {};
    const tempObjPrediction = {};

    for (let model of models) {
        tempObjForecast[model] = JSON.parse(JSON.stringify(emptyTimeSeriesObj));
        tempObjPrediction[model] = JSON.parse(JSON.stringify(emptyTimeSeriesObj));
    }

    convertedData.forecast = tempObjForecast;
    convertedData.prediction = tempObjPrediction;


    // //step 2
    ts_graph.forEach(item => {
        const { key, values } = item;

        if (key === "actual") {
            values.forEach(data => {
                convertedData.actual.dates.push(data.date);
                convertedData.actual.demands.push(data.demand);
            });
        } else {
            Object.keys(values).forEach(model => {
                values[model].forEach(data => {
                    convertedData[key][model].dates.push(data.date);
                    convertedData[key][model].demands.push(data.demand);
                });
            });
        }
    });
  

    let traces = generateTraces(convertedData);

    return traces;
}

function generateTraces(convertedData) {
    let traces = [];

    // Loop through forecast and prediction keys
    for (let method in convertedData.forecast) {

        // Create forecast object
        let forecastObj = {
            x: convertedData.forecast[method].dates,
            y: convertedData.forecast[method].demands,
        };
        let obj = traceValues.find(item => item.ts_type === 'forecast' && item.ts_model === method?.toLowerCase())
        Object.assign(forecastObj, obj.ts_props);

        // Push forecast object to traces array
        traces.push(forecastObj);


        // Create prediction object
        let predictionObj = {
            x: convertedData.prediction[method].dates,
            y: convertedData.prediction[method].demands,
        };

        // Attach the trace properties already defined to the trace object
        let objPrediction = traceValues.find(item => item.ts_type === 'prediction' && item.ts_model === method?.toLowerCase())
        Object.assign(predictionObj, objPrediction.ts_props);


        // Push prediction object to traces array
         traces.push(predictionObj);
    }

    // Add actuals object to traces array
    traces.unshift({
        x: convertedData.actual.dates,
        y: convertedData.actual.demands,
        type: 'scatter',
        name: 'actuals',
        mode: 'lines',
        marker: { color: 'black' }
    });

    return traces;
}

function getModels(ts_graph) {
    let models = [];
    let forecastItem = ts_graph.find(item => item.key === "forecast");
    models = Object.keys(forecastItem.values);
    return models;
}

function ModelsList(ts_graph) {
    let models = [];
    let forecastItem = ts_graph.find(item => item.key === "forecast");
    models = Object.keys(forecastItem.values);
    let result = models?.join(' ');
    return result;
}
//GetTracesFromTSGraphData(ts_graph);

export { GetTracesFromTSGraphData, ModelsList, getModels }