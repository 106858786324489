import { getLocalObject, getValue } from '../Utils/helper'
import { levelNumber } from '../Utils/filterScripts';
import filterEnums from '../Utils/enums'
/**
* get combinations in expected format
input format : 
[
 "1": {
      "state_id": 3,
      "store_id": 1,
      "cat_id": 1,
      "dept_id": 1,
      "item_id": 1
    },
    "2": {
      "state_id": 2,
      "store_id": 1,
      "cat_id": 1,
      "dept_id": 1,
      "item_id": 1
    }
]

expected format :
[   
     {
        "id" : 2      
      "name" : "Combination2",
      "params" : [
        { entityName : 'CA' , group:'group-a'} , {entityName : 'Foods' , group:'group-b'  }] 
    }
]
*/
function modifyCombinations(data) {
  let res = [];
  data.map((comb) => {
    let obj = {}
    let combObj = Object.entries(comb);
    combObj.map(elm => {
      let list = []
      obj['id'] = elm[0];
      obj['name'] = `Combination-${elm[0]}`;
      Object.entries(elm[1]).map((elem1) => {
       // list.push(getNameFromID(elem1[0], elem1[1]));
       list.push(getEnityNameAndGroup(elem1[0], elem1[1]));
      })
      obj['params'] = list;
    })

    return res.push(obj);
  });
  return res;
}

function getEnityNameAndGroup(key, val) {
  let mObject = {}
  let groupName = ''
  switch (key) {
    case 'state_id': groupName = filterEnums.states.group;
      mObject = getLocalObject(filterEnums.states.name);
      break;
    case 'store_id': groupName = filterEnums.stores.group; mObject = getLocalObject(filterEnums.stores.name); break;
    case 'cat_id': groupName = filterEnums.categories.group; mObject = getLocalObject(filterEnums.categories.name); break;
    case 'dept_id': groupName = filterEnums.departments.group; mObject = getLocalObject(filterEnums.departments.name); break;
    case 'item_id': groupName = filterEnums.products.group; mObject = getLocalObject(filterEnums.products.name); break;
    default: break;
  }
  return {entityName : mObject[val] , group : groupName};
}


function MasterListBasedOnPrevSelect(MasterList, filterEnum) {

  let l1Ids = [], l2Ids = [];
  let result = [];
  if (filterEnum.levelNumber === 1) {
    let PrevSelectionList = getValue("session", "form", filterEnum.name);
    let items = PrevSelectionList.filter(elem => elem.checked === true && elem.leafnode === true)
    l1Ids = items.map(elem => elem._id.split('-')[1]);
    result = MasterList.filter(existsInSelIds);

  } else if (filterEnum.levelNumber === 2) {
    let PrevSelectionList = getValue("session", "form", filterEnum.name);
    l1Ids = PrevSelectionList.filter(e => levelNumber(e._id, 'dept') === 1 && (e.indeterminate || e.checked))
      .map(f => f._id.replace('dept', 'item'))
    l2Ids = PrevSelectionList.filter(e => e.checked && e.leafnode)
      .map(f => f._id.replace('dept', 'item'))

    let res = MasterList.filter(elem => l1Ids.includes(elem.l1_id));

    result = res.map(({ l1_id, l1_name, l2_list }) => ({ l1_id, l1_name, l2_list: l2_list.filter(e => l2Ids.includes(e.l2_id)) }))
  }

  return result;

  function existsInSelIds(value) {
    let id = -1;
    id = value.l1_id.split('-')[1];
    return l1Ids.includes(id);
  }
}




export { modifyCombinations, MasterListBasedOnPrevSelect };


