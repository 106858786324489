import React, { useState , useEffect} from 'react'
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getModels } from './ScriptForGraph';
import TableComponent from '../../components/common/BasicTable';
import '../../assets/css/button01.css';
import * as XLSX  from 'xlsx';
import { displayMessage } from '../../Utils/helper';

function TabularViewContainer({ data, CombinationName }) {
    // Convert to another format
    let {id : workflowName} = useParams();
    let outputData = [];
    const [models, setModels] = useState([]);
    useEffect(() => {
        // Fetch and set models when data or component mounts
        if (data) {
            const fetchedModels = getModels(data.ts_graph);
            setModels(fetchedModels);
        }
    }, [data]); // Update models when data changes

    function convertToFormat(inputData) {
        const outputData = [];
    
        // Extract actual data
        const actualData = inputData.find(item => item.key === "actual")?.values || [];
    
        actualData.forEach(actualItem => {
            const date = actualItem.date;
            const actualDemand = actualItem.demand;
    
            // Initialize forecast and prediction objects with null values
            const forecast = {};
            const prediction = {};
            inputData.forEach(item => {
                const key = item.key;
                if (key === "forecast" || key === "prediction") {
                    const modelData = item.values;
                    Object.keys(modelData).forEach(model => {
                        const matchingValue = modelData[model].find(value => value.date === date);
                        if (key === "forecast") forecast[`F_${model}`] = matchingValue ? matchingValue.demand : null;
                        else if (key === "prediction") prediction[`P_${model}`] = matchingValue ? matchingValue.demand : null;
                    });
                }
            });
    
            // Create the output entry
            const outputEntry = {
                Date: date,
                Actuals: actualDemand,
                ...forecast,
                ...prediction
            };
    
            // Add the entry to the output data
            outputData.push(outputEntry);
        });
    
        return outputData;
    }
    
    function ModelColumns(type) {
        let output = [];
        
        models.forEach(item => {
            let col = {};
            col.Header = item;
            col.center = true;
            if (type === 'forecast') {
                col.accessor = "F_" + item;
            } else if (type === 'prediction') {
                col.accessor = "P_" + item;
            } else {
                // Handle other types or default case
                return []; // This line is unnecessary and causes the function to return undefined
            }
            output.push(col);
        });
        return output; // Return the output array outside the forEach loop
    }

    const handleClickBtnDownload = () => {
        let dom_element = document.getElementById('table1');
        var workbook = XLSX.utils.table_to_book(dom_element);
        const sheetNames = workbook.SheetNames;

        if (sheetNames.length > 0) {
              XLSX.writeFile(workbook, `tabledata_${workflowName}_${CombinationName}` + '.xlsx');
        } else {
              displayMessage('warning', 'No Content', 'No Content to download');
        }
    }
    
    outputData = convertToFormat(data.ts_graph);

    if (data && models?.length > 0) {
      
        let  forecast_models = ModelColumns('forecast');
        let  prediction_models = ModelColumns('prediction');
        let columns = [
  
            {
                "Header": "Date",
                "accessor": "Date",
                center: true
            },
            {
                "Header": "Actuals",
                "accessor": "Actuals",
                center: true
            },
            {
                "Header": "Forecast",
                "columns": forecast_models,
                center: true
            },
            {
                "Header": "Prediction",
                "columns": prediction_models,
                center: true
            }
        ]
     
        return (
            <Wrapper>    
                  <h1>Tabular View</h1>    
                <div className="controls">
                    <button onClick={handleClickBtnDownload} className='button-1' >Export Data</button>
                </div> 
                <div className='table-container' >
                <TableComponent inputData={outputData} columnNames={columns} />
                </div>
            </Wrapper>
        )
    }

}

export default TabularViewContainer;

const Wrapper = styled.div`
display :flex;

flex-direction : column;
justify-content: left;
width:100%;

.table-container{
    width:100%;
    height:80vh;
    overflow-y:auto;
}

.table-container::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: var(--clr-primary-1);
}

.table-container::-webkit-scrollbar
{
	width: 5px;
	background-color:  var(--clr-primary-1);
}

.table-container::-webkit-scrollbar-thumb
{
	background-color:  var(--clr-primary-10);
	border: 2px solid  var(--clr-primary-8);
}

.controls{
    text-align : right;
    margin:0.5rem;
}

.content{
    margin-top:10px !important;
}
`