import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { Navbar, Footer, AnimateOnScroll } from '../components'
import Card from '../components/frontpage/Card';
import Card2 from '../components/frontpage/Card2';
import { CarouselImage1, CarouselImage2 } from '../assets'
import Carousel from 'react-bootstrap/Carousel';
import { Cards, Cards2 } from '../Utils/CardsData';
import { device } from '../Utils/device';
import { CompanyLogo , ApplicationLogo } from '../assets/index'



function FrontPage() {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(()=>{
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }

    window.addEventListener('scroll',handleScroll);
    return () => {
      window.removeEventListener('scroll',handleScroll)
    }

  },[])

  return (
    <Wrapper>

      <AnimateOnScroll offset={20} >
      <Navbar />
        <main>
        <div className='carousels-section'>
            <div className='cust_container'>
              <Carousel fade  controls={false}  indicators={true}>
                <Carousel.Item>
                  <div className="background">
                    <img src={CarouselImage1} />
                  </div>
                  <Carousel.Caption className='cust-carousel-caption'>
                    <p>Empowering enterprises <br/> with robust predictive analytics</p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img src={CarouselImage2} />
                  <Carousel.Caption className='cust-carousel-caption'>
                    <p>Helping to plan <br/>and make informed decisions</p>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>

          <div className="card-section">
            <div className="card-header" data-animation="slideInDown" data-animation-delay=".2s" >
              <p>Key Benefits</p>
            </div>
            <div className="card-container">
              {Cards.map((cd, i) => (<Card key={i} ImageSource={cd.ImageSource} Name={cd.Name} Description={cd.Description} Animation={cd.Animation} AnimationDelay={cd.AnimationDelay} />))}
            </div>

          </div>

          <div className="sect-c">
            <div className="sect-c-header">
              <p>Features</p>
            </div>
            <div className="sect-c-container">
              {Cards2.map((cd, i) => (<Card2 key={i} icon={cd.Icon} heading={cd.Heading} content={cd.Content} />))}
            </div>
          </div>


        </main>
      </AnimateOnScroll>
      <Footer />
    </Wrapper>
  )
}

export default FrontPage;
const Wrapper = styled.div`
.card-section{
 display:block;
}

.card-header{
  text-align:center;
  font-weight:bold;
  font-size : 4rem;
  padding:4rem;
  background: linear-gradient(to bottom, hsl(24, 100%, 29%) 0%,rgba(125,185,232,0) 100%);
  p{
    color:var(--clr-grey-1);
  }
}

.card-container{
   display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 10rem;
    row-gap : 4rem;
    justify-content: center;
    padding-bottom: 4rem;

}

.carousels-section{
  display:flex;
  justify-content:center;
  height:fit-content;
}

.cust_container{
  width:100vw;
  height:fit-content;
  img {
  width:100vw;
  height:100vh;
  object-fit:fill;
  }
}
.cust-carousel-caption{
  top:18rem;
  left:5rem;
  position: absolute;
  font-size:68px;
  font-weight:900;
  p{
    color:white;
    line-height:1.2;
    text-align:left;
  }
}

.sect-c{
  margin:3rem 0px;
  height:fit-content;
  background-color:var(--clr-grey-10);
  .sect-c-header{
    padding-top:2rem;
    text-align:center;
    font-size : 4rem;
    font-weight:bold;
  }
  .sect-c-container{
    display : grid;
    grid-template-columns : auto auto auto;
    margin:5rem;
    column-gap: 8rem;
    align-items:center;
    justify-content:center;

  }
}

@media screen and (${device.tablet}) {

  .card-header{
  font-size : 3rem;
}

  .card-container{
    display: grid;
    grid-template-columns: auto auto ;
    gap: 2rem;
    justify-content: center;
  }

  .cust_container{
  width:100vw;
  height:fit-content;
  img {
  width:auto;
  height:100vh;
  object-fit:cover;
  object-position:bottom;
  }
}
.cust-carousel-caption{
  top:9rem;
  left:5rem;
  position: absolute;
  font-size:48px;
  font-weight:900;
  p{
    color:white;
    line-height:1.2;
    text-align:center;
  }
}

.sect-c{
  margin:3rem 0px;
  height:fit-content;
  background-color:var(--clr-grey-10);
  .sect-c-header{
    padding-top:1rem;
    text-align:center;
    font-size : 3rem;
    font-weight:bold;
  }
  .sect-c-container{
    display : grid;
    grid-template-columns : auto auto;
    margin:5rem;
    column-gap: 6rem;
    align-items:center;
    justify-content:center;

  }
}

}
@media screen and  (${device.mobileL}) {

  .card-header{
  font-size : 2rem;
}

.card-container{
    display: grid;
    grid-template-columns: auto ;
    gap: 2rem;
    justify-content: center;
  }

  .cust_container{
  width:100vw;
  height:fit-content;
  img {
  width:auto;
  height:100vh;
  object-fit:cover;
  object-position:bottom;
  }
}
.cust-carousel-caption{
  top:9rem;
  left:5rem;
  position: absolute;
  font-size:32px;
  font-weight:900;
  p{
    color:white;
    line-height:1.2;
    text-align:center;
  }
}


.sect-c{
  
  height:fit-content;
  background-color:var(--clr-grey-10);
  .sect-c-header{
  
    text-align:center;
    font-size : 2rem;
    font-weight:bold;
  }
  .sect-c-container{
    display : grid;
    margin:2rem;
    grid-template-columns : auto;
    align-items:center;
    column-gap:1rem;
    justify-content:center;

  }
}
  }
`
