import React from 'react'
import { useParams } from 'react-router-dom';

import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import '../../assets/css/button01.css';
import * as XLSX  from 'xlsx';
import { displayMessage } from '../../Utils/helper';




function ErrorMetrics({ data , CombinationName }) {
  let {id : workflowName} = useParams();

  if (data == null) {
    return false;
  }

  const convertErrorMetricsToFlatTableFormat = (data) => {
    let result = [];

    for (const model in data) {
      const modelData = data[model];

      for (const metric in modelData.training) {
        const entry = {
          "errorMetric": metric,
          "model": model,
          "training": modelData.training[metric],
          "validation": modelData.validation[metric]
        };
        result.push(entry);
      }
    }
    result = result.sort(customSortErrorMetric);

    return result;
  };

  // Custom sorting function for errorMetric
  function customSortErrorMetric(a, b) {
    // First, sort by errorMetric
    if (a.errorMetric < b.errorMetric) {
      return -1;
    } else if (a.errorMetric > b.errorMetric) {
      return 1;
    }

    // If errorMetrics are equal, then sort by model
    if (a.model < b.model) {
      return -1;
    } else if (a.model > b.model) {
      return 1;
    }

    // If both errorMetric and model are equal, no change in order
    return 0;
  }

  const handleClickBtnDownload = () => {
    let dom_element = document.getElementById('tblErrorMetrics');
    var workbook = XLSX.utils.table_to_book(dom_element);
    const sheetNames = workbook.SheetNames;

    if (sheetNames.length > 0) {
          XLSX.writeFile(workbook, `error_metrics_${workflowName}_${CombinationName}.xlsx`);
    } else {
          displayMessage('warning', 'No Content', 'No Content to download');
    }
}


  let formattedData = convertErrorMetricsToFlatTableFormat(data);



  return (
    <Wrapper>
      <div className='container' >
        <h1>Error Metrics</h1>
        <div className="controls">
                    <button onClick={handleClickBtnDownload} className='button-1' >Export Data</button>
        </div> 
        <div className='table-container' >
          <Table id="tblErrorMetrics" bordered hover className='scrollable-table' >
            <thead>
              <tr>
                <th>Metric Name</th>
                <th>Model</th>
                <th>Training</th>
                <th>Validation</th>
              </tr>
            </thead>
            <tbody>
              {
                formattedData.map((item, index) => (
                  <tr key={index} className={item.errorMetric == 'MAPE' ? 'bgcolor-grey' : 'red'} >
                    <td>{item.errorMetric}</td>
                    <td>{item.model}</td>
                    <td>{item.training}</td>
                    <td>{item.validation}</td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </div>
      </div>
    </Wrapper>

  )
}

export default ErrorMetrics;

const Wrapper = styled.div`
display :flex;

flex-direction : column;
justify-content: left;
width:100%;
.bgcolor-grey > td{
  background-color: var(--clr-primary-10);
}

#tblErrorMetrics{
  font-size:14px;
   thead tr th{
    background-color : var(--clr-primary-5);
    color : white;
   }
}

.controls{
    text-align : right;
    margin:0.5rem;
}


`
