import React from 'react'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { device } from '../../Utils/device';

function Card2({ icon, heading, content }) {
  return (
    <Wrapper className='Card2'>
        {/* <span> <i><FontAwesomeIcon icon={icon} /></i> </span> */}
        <p className='heading' >{heading}</p>
        <p className='content'>{content}</p>
    </Wrapper>
  )
}

export default Card2;

const Wrapper = styled.div`
 text-align:center;
 width:100%;
.heading {
      font-weight: bold;
      font-size:28px;
    }

    @media screen and  (${device.mobileL}) {  
      .heading {
      font-weight: bold;
      font-size:18px;
    }
    }
`