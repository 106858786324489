import React from 'react'
import styled from 'styled-components'
import { ApplicationLogo, CompanyLogo, logosocialmedia } from '../../assets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMobileAlt, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'

function Footer() {
  const LocationURL = 'https://maps.app.goo.gl/GjmSCUA21gBY4h6N7';
  const LinkedInURL = 'https://www.linkedin.com/company/quation-solutions-private-limited/';
  const TwitterURL = 'https://twitter.com/Quation20';
  const FacebookURL = 'https://www.facebook.com/profile.php?id=100093527806414/';
  const PhoneNumber = '+919652415296'
  const EmailAddress = 'contactus@quation.in'
  const QuationSite = 'https://quation.in/'
  const QuationSupportURL = 'https://quation.in/contact/'

  return (
    <Wrapper>
      <div className="row m-5 section-a" >

        <div className="col-sm-1 text-center"></div>
        <div className="col-sm-3 ">
          <h4>
            {" "}
            <img className="m-1" src={ApplicationLogo} style={{ height: "50px" }} />

          </h4>

          <div>
            <p>Predict the future and take meaningful action in the present</p>

          </div>
        </div>
        <div className="col-sm-2 ">
          <h4>Company</h4>
          <p> <a href={QuationSite} target='_blank' >About Us</a> </p>
          <p><a href={QuationSupportURL} target='_blank' >Support</a></p>
        </div>
        <div className="col-sm-2 ">
          <h4>Features</h4>
          <p>Data Modelling</p>
          <p>Analytics</p>
          <p>Decision Support</p>
        </div>
        <div className="col-sm-4">
          <h4>Contact Us</h4>
          <div className='row mb-3' >
            <div className="col-1 d-flex align-items-center">
              <a href={LocationURL} target='_blank' ><i className='fa-lg' ><FontAwesomeIcon icon={faLocationDot} /></i></a>
            </div>
            <div className="col-10">
              <a href={LocationURL} target='_blank' > <span>Quation Solutions Private Limited <br />
                6th Floor, Tower 3, WARP SJR I Park, EPIP Zone Opp. Sri Sathya Sai
                Hospital Whitefield Bangalore</span></a>
            </div>
          </div>
          <div className='row  mb-3' >
            <div className="col-1 d-flex align-items-center">
              <a href={"mailto:" + EmailAddress}><i className='fa-lg' ><FontAwesomeIcon icon={faEnvelope} /></i></a>
            </div>
            <div className="col-10">
              <a href={"mailto:" + EmailAddress}>contactus@quation.in</a>
            </div>
          </div>
          <div className='row mb-4' >
            <div className="col-1 d-flex align-items-center">
              <a href={'tel:' + PhoneNumber} target='_blank' >  <i className='fa-lg' ><FontAwesomeIcon icon={faMobileAlt} /></i></a>
            </div>
            <div className="col-10">
              <a href={'tel:' + PhoneNumber} target='_blank' > <span>+91-9652415296</span></a>
            </div>
          </div>
          <p>
            <span className='brand-links' >
              <a href={FacebookURL} target='_blank' ><i className='fa-2x mr-2'><FontAwesomeIcon icon={faFacebook} /></i></a>
              <a href={TwitterURL} target='_blank' > <i className='fa-2x' ><FontAwesomeIcon icon={faTwitter} /></i></a>
              <a href={LinkedInURL} target='_blank' >  <i className='fa-2x' ><FontAwesomeIcon icon={faLinkedin} /></i></a>
            </span>
          </p>

        </div>

        {/* <div className=" d-flex align-items-center justify-content-center">Contact Us </div>  */}
      </div>
      <div className="text-center p-2 section-b" >

        <img
          className=""
          style={{ width: "160x", height: "35px" }}
          src={CompanyLogo}
        />
      </div>
      <div className=" text-center p-2 section-c" >
        ©2023 Quation Solutions Pvt. Ltd., All rights reserved
      </div>
    </Wrapper>
  )
}


export default Footer;

const Wrapper = styled.footer`

 background-color:var(--clr-grey-10);
 color : black;
 a {
  color : black;
  :link {
  text-decoration: none;
}

:visited {
  text-decoration: none;
}
:hover {
  color : var(--clr-primary-1);
  text-decoration: underline;
}

:active {
  text-decoration: underline;
}
 }

 .section-c , .section-b{
  background-color:var(--clr-grey-10);
 }

 h4{
  margin-bottom:1rem;
  margin-top:1rem;
 }

.brand-links{
  display:flex;
  flex-direction:row;
  justify-content:start;
  a{
    margin-right : 1rem;
    
  }
}

`