import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { setValue , getValue,displayMessage } from '../../Utils/helper'
import moment from 'moment';
import { IterativeRadioList } from '../index'
import { granularityList } from '../../Utils/staticData';
import { device } from '../../Utils/device';


function SectionB() {
 
  const beginDateRef = useRef();
  const endDateRef = useRef();
  const cutOffDateRef = useRef();
  //const granularityRef = useRef();
  const dataAvail = {  from : getDateAvailability('from') , to : getDateAvailability('to') };

  function getDateAvailability(str){
    let dataAvail = getValue('local','masterdata','data_availability');
    return moment(dataAvail?.[str], 'DD-MM-YYYY').format('YYYY-MM-DD');
  }



  useEffect(() => {
    let formStr = sessionStorage.getItem('form');
    if (formStr) {
      let form = JSON.parse(formStr)
      let granItem = document.querySelector(`input[id='${form.granularity}']`);
      if (granItem) granItem.checked = true;
      //granularityRef.current.value = form.workflow_name;
      // beginDateRef.current.value =form.start_date; 
  
      beginDateRef.current.value = moment(form.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
      cutOffDateRef.current.value = moment(form.cut_off_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
      endDateRef.current.value = moment(form.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
    } else {
      console.log('session data not available')
    }
  })
  function fundamentalDateValidation(htmlElement, dateName){
    let date = moment(htmlElement.value,'YYYY-MM-DD');
    if(date.isValid()){
      let beginDate = date;
      let fromDate= moment(dataAvail.from, 'YYYY-MM-DD')
      let toDate = moment(dataAvail.to, 'YYYY-MM-DD')
      let diff = beginDate.diff(fromDate,'d');      
      let diff2 = toDate.diff(beginDate,'d'); 
      if( diff < 0 || diff2 < 0 ){
        htmlElement.classList.add('error-text');
        displayMessage("danger", `Invalid ${dateName} Date`, `${dateName} date should be within the range of data availability`); 
        return false;
      }else{
        htmlElement.classList.remove('error-text');
        return true;
      }
    }else{
      htmlElement.classList.add('error-text');
      displayMessage("danger", "Invalid Date", `${dateName} Date is invalid`); 
      return false;
    }
  }

  const txtBeginDateOnBlurHandler = (e) => {     
    let flag = fundamentalDateValidation(e.target,'Begin');
    if(flag){
    setValue('session', 'form', "start_date", moment(e.target.value).format('DD-MM-YYYY'));
    }
  }

  const txtEndDateOnBlurHandler = (e) => {
  let flag = fundamentalDateValidation(e.target,'End');
  if(flag){
  setValue('session', 'form', "end_date", moment(e.target.value).format('DD-MM-YYYY'));
  }
  }

  const txtCutOffDateOnBlurHandler = (e) => {
  let flag = fundamentalDateValidation(e.target,'Cut Off');
  if(flag){
  setValue('session', 'form', "cut_off_date", moment(e.target.value).format('DD-MM-YYYY'));
  }
  } 

  const rdGranularityOnChangeHandler = (e) => {
    setValue('session', 'form', "granularity", e.target.id);
  }


  return (
    <Wrapper>
            <div id="notice" className="control-section date-notice">
        * Data available for forecast is between {  moment(dataAvail.from, 'YYYY-MM-DD').format('DD-MM-YYYY')} to {  moment(dataAvail.to, 'YYYY-MM-DD').format('DD-MM-YYYY')} 
      </div>
      
      <div className='control-section dates-section' >
        <div>
          <label className='mandatory lbl-for-tb'>Begin Training </label>
          <input ref={beginDateRef} type="date" name="beginDate" 
          onBlur={txtBeginDateOnBlurHandler}
          min={dataAvail.from}
          max={dataAvail.to}  />
        </div>
        <div>  
          <label className='mandatory lbl-for-tb'>Train Until</label>
          <input ref={cutOffDateRef} type="date" name="cutOffDate" onBlur={txtCutOffDateOnBlurHandler}
            min={dataAvail.from}
            max={dataAvail.to}  />
        </div>
        <div> 
          <label  className='mandatory lbl-for-tb'>Forecast Until</label>
          <input ref={endDateRef} type="date" name="endDate" onBlur={txtEndDateOnBlurHandler}
            min={dataAvail.from}
            max={dataAvail.to}  />
        </div>
      </div>




      <div className='control-section'>
        <label  className='mandatory lbl-for-tb'>Forecast frequency</label>
        {/* <div className="gran">
          <div>
          <input 
              ref={granularityRef} 
              type="radio" 
              name="granularity" 
              id="daily" 
              onChange={rdGranularityOnChangeHandler} 
            />
            <label htmlFor="daily" >Daily</label>
          </div>

          <div>
          <input 
              ref={granularityRef} 
              type="radio" 
              name="granularity" 
              id="weekly" 
              onChange={rdGranularityOnChangeHandler} 
            />
            <label htmlFor="weekly" >Weekly</label>
          </div>

          <div>
         <input 
              ref={granularityRef} 
              type="radio" 
              name="granularity" 
              id="monthly" 
              onChange={rdGranularityOnChangeHandler} 
            />
            <label htmlFor="monthly" >Monthly</label>
          </div>

          <div>
            <input ref={granularityRef} type="radio" name="granularity" id="quaterly" onChange={rdGranularityOnChangeHandler} />
            <label htmlFor="quaterly" >Quarterly</label>
          </div>

          <div>
            <input ref={granularityRef} type="radio" name="granularity" id="yearly" onChange={rdGranularityOnChangeHandler} />
            <label htmlFor="yearly" >Yearly</label>
          </div>
        </div> */}
        <IterativeRadioList ListName='granularity' IterativeData={granularityList} DefaultValue={'weekly'} OnSelectChange={rdGranularityOnChangeHandler}/>

        </div>
    </Wrapper>
  )
}

export default SectionB;

const Wrapper = styled.div`
display:flex;
flex-direction:column;




.dates-section , .gran {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dates-section {
    margin-bottom: 1rem;
 
  
    div {
        display: flex;
        flex-direction: column;  
        justify-content: flex-start;
        color: var(--clr-primary-3);
        input[type='date'] {
          width: 13rem;
        }
    }
}
.date-notice{
  text-align: left;
    color: var(--clr-primary-1);
    font-style: italic;
    font-size: 14px;
}

.gran {
    border-radius : var(--radius);
    div {   
      width:100%;   
        label {
            width:100%;   
            color: black;
            font-style:normal;
            background: var(--clr-primary-9);
            border: 1px solid transparent;
            border-radius: 2px;
            display: inline-block;
            height: fit-content;
            text-align:center;
            
            line-height: 26px;
            margin: 2px;
            padding: 5px;
            transition: .2s all ease-in-out;
            cursor:pointer;
        }
        input[type="radio"] {
        display: none;
        }
        input[type="radio"]:checked + label {
            background: var(--clr-primary-5);
            border: 1px solid $form-border-color;
        }
    }
}

@media screen and  (${device.tablet}) {
  .dates-section , .gran {
    display: flex;
    flex-direction: column;
    row-gap :1.5rem;    

}
input[type='date'] {
          width: 90vw !important;
        }
}

`