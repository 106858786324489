// this is used in SectionA1 of WFCreate 
const listDbType = [
    {label : "SQL", value:"SQL"},
    {label : "MySQL", value:"MySQL"},
    {label : "Postgres", value:"Postgres"},
    {label : "MongoDb", value:"MongoDb"}
]

const listDatabases = [
  {label : "database1",  value:"database1"},
  {label : "database2",  value:"database2"},
  {label : "database3",  value:"database3"},
  {label : "database4",  value:"database4"}
]

const listDataTables = [
  {label : "datatable1",  value:"datatable1"},
  {label : "datatable2",  value:"datatable2"},
  {label : "datatable3",  value:"datatable3"},
  {label : "datatable4",  value:"datatable4"}
]

// this is used in SectionA1 of WFCreate
const wfCreateFileUploadFormats = [
    {label : "csv", value:"csv"},
    {label : "xlsx", value:"xlsx"},
    {label : "perk", value:"perk"}
]

const granularityList =[
    { id: 'Daily', label: 'Daily' },
    { id: 'Weekly', label: 'Weekly' },
    { id: 'Monthly', label: 'Monthly' },
    { id: 'Quarterly', label: 'Quarterly' },
    { id: 'Yearly', label: 'Yearly' },
  ]

  const levelsList =[
    { value: 'Level1', label: 'Level1' },
    { value: 'Level2', label: 'Level2' },
    { value: 'Level3', label: 'Level3' },
    { value: 'Level4', label: 'Level4' },
    { value: 'Level5', label: 'Level5' },
    { value: 'Level6', label: 'Level6' },
    { value: 'Level7', label: 'Level7' },
    { value: 'Level8', label: 'Level8' },
    { value: 'Level9', label: 'Level9' },
    { value: 'Level10', label: 'Level10' },
    { value: 'Level11', label: 'Level11' },
    { value: 'Level12', label: 'Level12' },
  ]

const DataIngestType = [ { id: 'Database', label: 'Database' , checked : true },
{ id: 'FileUpload', label: 'File Upload' },
]


const levelAggregation = [
  {
    "level_id": "1",
    "level_description": "Unit sales of all products, aggregated for all stores/states",
    "no_of_series": "1",
    "values": " each row one series"
  },
  {
    "level_id": "2",
    "level_description": "Unit sales of all products, aggregated for each State",
    "no_of_series": "3",
    "values": "CA, TA, WI"
  },
  {
    "level_id": "3",
    "level_description": "Unit sales of all products, aggregated for each store ",
    "no_of_series": "10",
    "values": "CA_1, CA_2, CA_3, CA_4, TX_1, TX_2, TX_3, WI_1, WI_2, WI_3"
  },
  {
    "level_id": "4",
    "level_description": "Unit sales of all products, aggregated for each category",
    "no_of_series": "3",
    "values": "Hobbies, Foods, and Household"
  },
  {
    "level_id": "5",
    "level_description": "Unit sales of all products, aggregated for each department",
    "no_of_series": "7",
    "values": "FOOD_1, FOOD_2, FOOD_3, HOBBIES_1, HOBBIES_2, HOUSEHOLD_1, HOUSEHOLD_2"
  },
  {
    "level_id": "6",
    "level_description": "Unit sales of all products, aggregated for each State and category",
    "no_of_series": "9",
    "values": "HOBBIES_CA, HOBBIES_TX, HOBBIES_WI, ,FOODS_CA, FOODS_TX, FOODS_WI, HOUSEHOLD_CA, HOUSEHOLD_TX, HOUSEHOLD_WI"
  },
  {
    "level_id": "7",
    "level_description": "Unit sales of all products, aggregated for each State and department",
    "no_of_series": "21",
    "values": " (7 department X 3 State)"
  },
  {
    "level_id": "8",
    "level_description": "Unit sales of all products, aggregated for each store and category",
    "no_of_series": "30",
    "values": " (10 store X 3 Category)"
  },
  {
    "level_id": "9",
    "level_description": "Unit sales of all products, aggregated for each store and department",
    "no_of_series": "70",
    "values": " (10 store X 7 department)"
  },
  {
    "level_id": "10",
    "level_description": "Unit sales of product x, aggregated for all stores/states",
    "no_of_series": "3,049",
    "values": " CA_1(3049 records), CA_2(3049 records), CA_3(3049 stores), CA_4(3049 records), TX_1, TX_2, TX_3, WI_1, WI_2, WI_3(3049 records)"
  },
  {
    "level_id": "11",
    "level_description": "Unit sales of product x, aggregated for each State",
    "no_of_series": "9,147",
    "values": " (3049 records in a store X 3 states)"
  },
  {
    "level_id": "12",
    "level_description": "Unit sales of product x, aggregated for each store",
    "no_of_series": "30,490",
    "values": " 3049 records in a store X 10 stores)"
  }
]

export { listDbType , 
  wfCreateFileUploadFormats, 
  granularityList, 
  DataIngestType, 
  levelsList, 
  levelAggregation,
  listDatabases,
  listDataTables}