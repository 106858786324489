import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

function IterativeRadioList({ ListName, IterativeData, DefaultValue, OnSelectChange }) {

  // State to manage the checked status of the radio buttons
  const [checkedOption, setCheckedOption] = useState(DefaultValue); // Set Option 1 as checked initially

  // Function to handle radio button change
  const handleRadioChange = (e) => {
    setCheckedOption(e.target.value); // Update the checked option based on user selection
    OnSelectChange(e)
  };

  return (
    <Wrapper className='rblist' >
      {IterativeData.map(item => (
        <div key={item.id}>
          <input
            type="radio"
            id={`${item.id}`}
            name={ListName}
            checked={checkedOption === item.id}
            value={item.id}
            onChange={handleRadioChange} />
          <label htmlFor={item.id}>{item.label}</label>
        </div>
      ))}
    </Wrapper>
  )
}

export default IterativeRadioList;


const Wrapper = styled.div`
border-radius : var(--radius);
display: flex;
flex-direction: row;
-webkit-box-pack: justify;
justify-content: space-between;
    div {   
      width:100%;   
        label {
            width:100%;   
            color: var(--clr-neutral-1);
            border-color :var(--clr-neutral-1);
            font-style:normal;           
            border: 1px solid;
            border-radius: 2px;
            display: inline-block;
            height: fit-content;
            text-align:center;            
            line-height: 26px;
            margin: 2px;
            padding: 5px;
            transition: .2s all ease-in-out;
            cursor:pointer;
            &:hover{
                color :var(--clr-neutral-1);
            }
        }
        input[type="radio"] {
        display: none;
        }
        input[type="radio"]:checked + label {
            background: var(--clr-primary-5);
            border: 1px solid $form-border-color;
            &:hover{
                color :var(--clr-neutral-1);
            }
        }
    }
`