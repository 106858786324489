import HttpService from '../services/HttpService'

const ApiEnums = {
  masterdata: "/masterdata",
  workflows: "/workflows",
  workflow: "/workflow",
  workflow_form: "/workflow_form",
  wf_combination_report: "/wf_combination_report",
  wf_name_check :'/wf_name_check',
  count_record :'/count_record',
  databaselist : '/databaselist',
  dataingestiondb : '/dataingestiondb',
  getsampledata : '/getsampledata',
}


async function postRequest(requestObject) {
  try{
    const axiosClient = HttpService.getAxiosClient();
    const responseObject = await axiosClient.post(requestObject?.url, requestObject?.data, requestObject?.config);
    if (responseObject?.status === 200 || responseObject?.status === 201) {
      return responseObject.data;
    }
    else {
      console.log('Error in DataRequestService.postRequest');
      console.log(responseObject)
      return { status : responseObject?.status, output : responseObject };
    }
  }catch(error){
    console.log(error);
    return { status : 'error', 
             output : error.message };
  } 
}

function getRequest(requestObject) {
  const axiosClient = HttpService.getAxiosClient();
  let responseObject = axiosClient.get(requestObject.url, requestObject.config)
    .then(response => responseObject = response.data)
    .catch(error => {
      console.log('Error in DataRequestService.getRequest');
      console.error(error);
      return responseObject = 'error';
    });

  return responseObject;
}

export { ApiEnums, postRequest, getRequest }