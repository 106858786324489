import React, { useEffect, useState, useRef } from 'react'
import { useLoaderData, redirect } from "react-router-dom";
import styled from 'styled-components';
import { Tabs, Tab, WFFormValidateModal, MultiLevelCbControl, ErrorText } from '../components'
import { getModelList, levelNumber } from '../Utils/filterScripts';
import '../assets/css/tabs.css'
import $ from 'jquery'
import { getSiblings, displayMessage, renameKey, setValue, getValue } from '../Utils/helper'
import { v4 as uuidv4 } from 'uuid';
import { createPortal } from 'react-dom';
import moment from 'moment';
import filterEnums from '../Utils/enums';
import HttpService from '../services/HttpService'
import UserService from '../services/UserService'
import { postRequest, ApiEnums } from '../services/DataRequestService';
import { MasterListBasedOnPrevSelect } from '../services/DataManipulationService';
import businessTabStyles from '../assets/css/business-tabs.module.css'


function WorkflowCreationPage() {

  const { masterData: data } = useLoaderData();

  const TabsRef = useRef();
  const workflowNameRef = useRef();
  const workflowDescriptionRef = useRef();
  const beginDateRef = useRef();
  const endDateRef = useRef();
  const cutOffDateRef = useRef();
  const granularityRef = useRef();

  const [states, setStates] = useState([]);
  const [stores, setStores] = useState([]);
  const [categories, setCategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [products, setProducts] = useState([]);
  const [models, setModels] = useState([]);


  const [isSubListExists, setIsSubListExists] = useState(false);

  const [masterList, setMasterList] = useState([]);


  const [showModal, setShowModal] = useState(false);

  const [tabState, setTabState] = useState({ activeTab: TabsRef.current?.props.children[0].props.label });

  const changeTab = (tab) => {
    let SelMasterList = [];
    switch (tab.toLowerCase()) {

      case 'states': setMasterList(curr => curr = data.states);
        break;

      case 'stores':
        SelMasterList = MasterListBasedOnPrevSelect(data.stores, filterEnums.states);
        setMasterList(curr => curr = SelMasterList);
        break;

      case 'categories':
        setMasterList(curr => curr = data.categories);
        break;

      case 'departments': SelMasterList = MasterListBasedOnPrevSelect(data.departments, filterEnums.categories);
        setMasterList(curr => curr = SelMasterList);
        break;

      case 'products': SelMasterList = MasterListBasedOnPrevSelect(data.products, filterEnums.departments);
        setMasterList(curr => curr = SelMasterList);
        break;

      default: break;
    }
    setTabState({ activeTab: tab });
  };
  useEffect(() => {
    sessionStorage.clear();
    let formStr = sessionStorage.getItem('form');
    if (formStr) {
      let form = JSON.parse(formStr)
      workflowNameRef.current.value = form.workflow_name;
      workflowDescriptionRef.current.value = form.workflow_description;
      let granItem = document.querySelector(`input[id='${form.granularity}']`);
      if (granItem) granItem.checked = true;
      // granularityRef.current.value = form.workflow_name;
      beginDateRef.current.value = form.start_date;
      cutOffDateRef.current.value = form.cut_off_date;
      endDateRef.current.value = form.end_date;
      setStates(states => states = form.states);
      setStores(curr => curr = form.stores);
      setCategories(curr => curr = form.categories);
      setDepartments(curr => curr = form.departments);
      setProducts(curr => curr = form.products);
      setModels(curr => curr = form.models);


    } else {
      let id = 'wf_' + uuidv4();
      let form = {
        id: id,
        object: "WorkflowForm",
        workflow_name: "",
        workflow_description: "",
        start_date: "",
        cut_off_date: "",
        end_date: "",
        granularity: "",
        states: [],
        stores: [],
        categories: [],
        departments: [],
        products: [],
        models: ['ARIMA'],
        userid: UserService.getUserID(),
        ignore_invalid_combinations: false
      };
      sessionStorage.setItem("form", JSON.stringify(form));
      //  setModels(xs => xs = ['Model1','Model2','Model3'])
    }

  }, [])

  function IsSubCategoriesSelected(currListEnum, subListEnum, selectedValue) {
    let response = false;
    selectedValue = selectedValue.replace(currListEnum.listcode, subListEnum.listcode);
    let selChildrens = getValue("session", "form", subListEnum.name);
    if (selChildrens) {
      response = selChildrens.some(e => [selectedValue].includes(e._id) && (e.indeterminate || e.checked));
    }
    return response;
  }

  const CheckBoxOnChangeHandler = (filterEnum, e) => {

    let selObj = {};
    var checked = e.target.checked,
      container = e.target.parentNode;

    if (!checked) {
      let currId = e.target.id;
      let flag = false;
      switch (filterEnum.name) {
        case 'states': flag = IsSubCategoriesSelected(filterEnums.states, filterEnums.stores, currId);
          break;
        case 'stores': break;
        case 'categories': flag = IsSubCategoriesSelected(filterEnums.categories, filterEnums.departments, currId);
          break;
        case 'departments': flag = IsSubCategoriesSelected(filterEnums.departments, filterEnums.products, currId);
          break;
        case 'products': break;
        default: break;
      }
      setIsSubListExists(flag);
      if (flag) {
        return;
      }
    }

    var cbs = container.querySelectorAll('input[type="checkbox"]');
    const calcId = (id) => id = Number(id.split('-')[1]);

    const isLeafNode = (_id) => {
      let elem = Object.keys(filterEnums).find(elem => filterEnums[elem].name === filterEnum.name);
      let levelNumber = filterEnums[elem].levelNumber;
      let level_id = _id.split('-')[0].at(-1);
      if (levelNumber == level_id) {
        return true;
      } else {
        return false;
      }
    }

    [].forEach.call(cbs, function (cb) {

      cb.checked = checked;
      cb.indeterminate = false;
      selObj[cb.id] = {
        id: calcId(cb.id),
        _id: cb.id,
        checked: checked,
        indeterminate: false,
        leafnode: isLeafNode(cb.id),
        parent: cb.dataset.parent,
        levelNumber: cb.dataset.levelnumber
      };
    });

    function checkSiblings(el) {
      let parent = el.parentNode.parentNode;
      let all = true;


      let elSiblings = getSiblings(el);
      for (let sibling of elSiblings) {
        let returnValue = all = ($(sibling).children('input[type="checkbox"]').prop("checked") === checked);
        if (returnValue) {
          continue;
        } else {
          break;
        }
      }
      if (all && checked) {

        let _p = parent.querySelectorAll(':scope > input[type="checkbox"]')
        if (_p !== null) {
          for (let i = 0; i < _p.length; i++) {
            _p[i].checked = checked;
            _p[i].indeterminate = false;
            selObj[_p[i].id] = {
              id: calcId(_p[i].id), _id: _p[i].id.split('-')[1],
              _id: _p[i].id,
              checked: _p[i].checked,
              indeterminate: _p[i].indeterminate,
              leafnode: isLeafNode(_p[i].id),
              parent: _p[i].dataset.parent,
              levelNumber: _p[i].dataset.levelnumber
            };
          }

        }
        checkSiblings(parent);

      } else if (all && !checked) {

        let _p1 = parent.querySelectorAll(':scope > input[type="checkbox"]');
        if (_p1 !== null) {
          for (let i = 0; i < _p1.length; i++) {
            _p1[i].checked = checked;
            _p1[i].indeterminate = parent.querySelectorAll('input[type="checkbox"]:checked')?.length > 0;
            selObj[_p1[i].id] = {
              id: calcId(_p1[i].id),
              _id: _p1[i].id,
              checked: _p1[i].checked,
              indeterminate: _p1[i].indeterminate,
              leafnode: isLeafNode(_p1[i].id),
              parent: _p1[i].dataset.parent,
              levelNumber: _p1[i].dataset.levelnumber
            };
          }
        }
        checkSiblings(parent);

      } else {

        let _p2List = $(el).parents("li");
        for (let i = 0; i < _p2List.length; i++) {
          let _p2 = _p2List[i].querySelector(':scope > input[type="checkbox"]');
          _p2.indeterminate = true;
          _p2.checked = false;
          selObj[_p2.id] = {
            id: calcId(_p2.id),
            _id: _p2.id,
            checked: _p2.checked,
            indeterminate: _p2.indeterminate,
            leafnode: isLeafNode(_p2.id),
            parent: _p2.dataset.parent,
            levelNumber: _p2.dataset.levelnumber
          };
        }
      }
    }
    checkSiblings(container);

    switch (filterEnum.name) {
      case 'states': updateFilterState(selObj, states, setStates, filterEnum.name); break;
      case 'stores': updateFilterState(selObj, stores, setStores, filterEnum.name); break;
      case 'categories': updateFilterState(selObj, categories, setCategories, filterEnum.name); break;
      case 'departments': updateFilterState(selObj, departments, setDepartments, filterEnum.name); break;
      case 'products': updateFilterState(selObj, products, setProducts, filterEnum.name); break;
      case 'models': updateFilterState(selObj, models, setModels, filterEnum.name); break;

      default: break;
    }

  }

  function updateFilterState(selObj, filterState, setFilterState, filterName) {
    // //check and update in state array.
    let selArray = modifyKeyValue_Id(filterName, Object.values(selObj));
    let prevSelect = [...filterState];
    for (let item of selArray) {
      //if select contains 'item', update it else add that 'item' to select 
      let res = prevSelect.find(elem => elem._id == item._id);
      if (res === undefined) {
        prevSelect.push(item)
      } else {
        let i = prevSelect.findIndex(elem => elem._id === res._id)
        prevSelect.splice(i, 1);
        prevSelect.push(item);
      }
    }

    setFilterState(current => current = prevSelect);
    setValue('session', 'form', filterName, prevSelect);
  }

  function modifyKeyValue_Id(filterName, inputArray) {
    let listCode = null;
    Object.keys(filterEnums).forEach(elem => {
      if (filterEnums[elem].name === filterName) {
        listCode = filterEnums[elem].listcode;
      }
    });
    inputArray = inputArray.map(elem => elem = renameKey(elem, "id", `${listCode}_id`))
    return inputArray;
  }

  function wfCreateRequest(requestParam) {
    let requestObject = {
      url: ApiEnums.workflow_form,
      config: {
        params: {
          user_id: UserService.getUserID()
        }
      },
      data: requestParam
    }
    const responseObject = postRequest(requestObject);
    return responseObject;
  }

  const btnSubmitOnClickHandler = async (e) => {
    setValue('session', 'form', 'models', ["ARIMA"]);
    let form = JSON.parse(sessionStorage.getItem('form'));


    if (workflowNameRef.current.value === "") {
      displayMessage("danger", "Workflow Name", "Workflow Name cannot be empty");
      return false;
    }
    if (workflowDescriptionRef.current.value === "") {
      displayMessage("danger", "Workflow Description", "Workflow Description cannot be empty");
      return false;
    }

    let begin_date = moment(beginDateRef.current.value);
    let cutoff_date = moment(cutOffDateRef.current.value);
    let end_date = moment(endDateRef.current.value);
    if (!begin_date.isValid()) {
      displayMessage("danger", "Invalid Date", "Begin Date is invalid"); return false;
    }
    if (!cutoff_date.isValid()) {
      displayMessage("danger", "Invalid Date", "CutOff Date is invalid"); return false;
    }
    if (!end_date.isValid()) {
      displayMessage("danger", "Invalid Date", "End Date is invalid"); return false;
    }
    const diff = end_date.diff(begin_date, 'd');
    const diff2 = cutoff_date.diff(begin_date, 'd');
    const diff3 = end_date.diff(cutoff_date, 'd');
    if (diff < 0) {
      displayMessage("danger", "Date", "End date should not be lesser than begin date");
      return false;
    }
    if (diff2 < 0) {
      displayMessage("danger", "Date", "CutOff date should not be lesser than begin date");
      return false;
    }
    if (diff3 < 0) {
      displayMessage("danger", "Date", "End date should be greater than cutoff date");
      return false;
    }
    let granItem = document.querySelector(`div.gran input[type='radio']:checked`);
    if (granItem == null) {
      displayMessage("danger", "Granularity", "Please select a granularity");
      return false;
    }
    //model selection is mandatory
    if (form?.models.length == 0) {
      displayMessage("danger", "Select Model", "Please select a model");
      return false;
    }

    let respObj = await wfCreateRequest(form); //respObj shall we either invalid_combination , acknowledgement or error.

    if (respObj.hasOwnProperty('invalid_combinations')) {
      setValue('session', 'form', 'invalid_combinations', respObj.invalid_combinations);
      setShowModal(true);
      displayMessage("danger", "invalid_combinations", "invalid combinations exists");
    }
    else if (respObj.hasOwnProperty('acknowledgement')) {
      displayMessage("success", "Workflow", respObj.acknowledgement);
      redirect("/wflist");
    } else {
      if (respObj.hasOwnProperty('error')) {
        displayMessage("danger", "error", respObj.error);
      }
      else {
        displayMessage("danger", "error", "interal error");
      }
    }

    //e.preventDefault();
  }

  const txtWFNameOnBlurHandler = (e) => {
    setValue('session', 'form', "workflow_name", e.target.value);
  }

  const txtWFDescriptionOnBlurHandler = (e) => {
    setValue('session', 'form', "workflow_description", e.target.value);
  }

  const txtBeginDateOnBlurHandler = (e) => {
    setValue('session', 'form', "start_date", moment(e.target.value).format('DD-MM-YYYY'));
  }

  const txtEndDateOnBlurHandler = (e) => {
    setValue('session', 'form', "end_date", moment(e.target.value).format('DD-MM-YYYY'));
  }

  const txtCutOffDateOnBlurHandler = (e) => {
    setValue('session', 'form', "cut_off_date", moment(e.target.value).format('DD-MM-YYYY'));
  }

  const rdGranularityOnChangeHandler = (e) => {
    setValue('session', 'form', "granularity", e.target.id);
  }



  return (
    <Wrapper className='page' >

      <div className='section-center mainbox page-width'>
        <div className="wfform">

          <div className="wfheader">
            <span>Workflow Creation Form</span>
          </div>

          <div className="subsection">
            <div className='subheader' >
              <span>Basic Details</span>
            </div>
            <div className="wfname">
              <label>Workflow Name</label>
              <input type='text' ref={workflowNameRef} onBlur={txtWFNameOnBlurHandler} />
            </div>
            <div className="wfdescription">
              <label>Workflow Description</label>
              <textarea ref={workflowDescriptionRef} onBlur={txtWFDescriptionOnBlurHandler} ></textarea>
            </div>
          </div>

          <div className="subsection">
            <div className='subheader' >
              <span>Timeline Configuration</span>
            </div>
            <div>
              <div className='gran' >
                <div>
                  <label>Begin Date</label>
                  <input ref={beginDateRef} type="date" name="beginDate" onBlur={txtBeginDateOnBlurHandler} />
                </div>
                <div>  <label>Cut Off Date</label>
                  <input ref={cutOffDateRef} type="date" name="cutOffDate" onBlur={txtCutOffDateOnBlurHandler} />
                </div>
                <div> <label>End Date</label>
                  <input ref={endDateRef} type="date" name="endDate" onBlur={txtEndDateOnBlurHandler} />
                </div>
              </div>

              <span className='granHeader' >Granularity</span>

              <div className="gran">
                <div>
                  <input ref={granularityRef} type="radio" name="granularity" id="daily" onChange={rdGranularityOnChangeHandler} />
                  <label htmlFor="daily" >Daily</label>
                </div>

                <div>
                  <input ref={granularityRef} type="radio" name="granularity" id="weekly" onChange={rdGranularityOnChangeHandler} />
                  <label htmlFor="weekly" >Weekly</label>
                </div>

                <div>
                  <input ref={granularityRef} type="radio" name="granularity" id="monthly" onChange={rdGranularityOnChangeHandler} />
                  <label htmlFor="monthly" >Monthly</label>
                </div>

                <div>
                  <input ref={granularityRef} type="radio" name="granularity" id="quaterly" onChange={rdGranularityOnChangeHandler} />
                  <label htmlFor="quaterly" >Quaterly</label>
                </div>

                <div>
                  <input ref={granularityRef} type="radio" name="granularity" id="yearly" onChange={rdGranularityOnChangeHandler} />
                  <label htmlFor="yearly" >Yearly</label>
                </div>

              </div>
            </div>
          </div>

          <div className="subsection">
            <div className='subheader' >
              <span>Business Criteria Configuration</span>
            </div>
            <div>
              <Tabs ref={TabsRef} changeHandler={changeTab} tabState={tabState} style={businessTabStyles} >
                <Tab label="States"  name="States" >
                  <MultiLevelCbControl data={data.states} changeHandler={CheckBoxOnChangeHandler} selections={states} filterEnum={filterEnums.states} />
                  {
                    isSubListExists ? <ErrorText>Please unselect all the stores before  unselecting any state</ErrorText> : <></>
                  }
                </Tab>
                <Tab label="Stores" name="Stores">
                  {0 == 0 ?
                    <MultiLevelCbControl data={masterList} changeHandler={CheckBoxOnChangeHandler} selections={stores} filterEnum={filterEnums.stores} />

                    :
                    <ErrorText customClassName=""> please select </ErrorText>
                  }
                </Tab>
                <Tab label="Categories" name="Categories" >
                  <MultiLevelCbControl data={masterList} changeHandler={CheckBoxOnChangeHandler} selections={categories} filterEnum={filterEnums.categories} />
                  {
                    isSubListExists ? <ErrorText>Please unselect all the stores before  unselecting any state</ErrorText> : <></>
                  }
                </Tab>
                <Tab label="Departments" name="Departments" >
                  {0 == 0 ?
                    <>
                      <MultiLevelCbControl data={masterList} changeHandler={CheckBoxOnChangeHandler} selections={departments} filterEnum={filterEnums.departments} />
                      {
                        isSubListExists ? <ErrorText>Please unselect all the stores before  unselecting any state</ErrorText> : <></>
                      }
                    </> :
                    <ErrorText prev="States" curr="Stores" />
                  }
                </Tab>
                <Tab label="Products" name="Products" >
                  {0 == 0 ?
                    <MultiLevelCbControl data={masterList} changeHandler={CheckBoxOnChangeHandler} selections={products} filterEnum={filterEnums.products} />
                    :
                    <ErrorText prev="States" curr="Stores" />
                  }
                </Tab>
              </Tabs>
            </div>
          </div>



          <div className="subsection">
            <div className='subheader' >
              <span>Data Models</span>
            </div>

            <MultiLevelCbControl data={getModelList()} changeHandler={CheckBoxOnChangeHandler} selections={models} filterEnum={filterEnums.models} />
          </div>

          <div className="wfheader">
            <button className='btnForm' onClick={btnSubmitOnClickHandler} >Submit Form</button>
            {showModal && createPortal(
              <WFFormValidateModal onClose={() => setShowModal(false)} />,
              document.body
            )}
          </div>

        </div>
      </div>

    </Wrapper>
  )
}

export default WorkflowCreationPage;


const Wrapper = styled.div`
.wfheader{
  border:1px solid var(--clr-primary-3);
  border-radius:5px;
  text-align:center;
  background-color:var(--clr-primary-4);
  span{
    color : var(--clr-primary-1);
    font-size:1.5rem;
  }
}
.wfform{
  display:flex;
  width:100%;
  flex-direction:column;
  border: 2px solid rgb(240, 240, 240);
  border-radius: 12px;
  box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
  .subsection{

    .subheader{
      margin: 0 0;
      margin-bottom: 5px;
      width:100%;
      height:50px;
      line-height: 50px;
      background-color:var(--clr-primary-7);  
      border-radius:5px;
      span{
        text-align: center;
        font-size: larger;
        margin: 0 auto;
        display: block;
        color: var(--clr-primary-3);
        font-variant: all-petite-caps;
        font-weight: 700;
      }
    }
  }
 
  .gran{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    margin:1rem 4rem;
    div{
      label {
        color:var(--clr-primary-3); 
        margin-right:0.5rem;
      }
    }
  }

  .granHeader{ 
    font-size: larger;
    margin: 0 auto;
    display: block;
    color: var(--clr-primary-3);
    font-variant: all-petite-caps;
    font-weight: 700;
    padding-left:0.5rem;
    
  }
  
  div.wfname, div.wfdescription {
    padding-left:0.5rem;
    label {
      display:block;
      color:var(--clr-primary-3); 
    }
    input[type="text"]{
      text-transform:capitalize;     
    }
    textarea,input[type="text"]{
      width:40rem;      
    }
  }

  .btnForm{
    background:var(--clr-primary-7); 
    border-radius:0.5rem;
    border:1px solid var(--clr-primary-3);
    color:var(--clr-primary-3);
    :focus{
      background:var(--clr-primary-8); 
    }
    font-size:18px;
    font-weight:600;
    margin:5px 40px;
    padding:5px;
  }
}

.model-list{
  display : flex;
  flex-direction : row;
  justify-content:space-around;
  
}

`