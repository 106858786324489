import React from 'react'
import styled from 'styled-components'

export default function ErrorText(props) {
  return <Wrapper>
    {props.children}
  </Wrapper>
}

const Wrapper = styled.div`
  font-size : 14px;
  border-radius:var(--radius);
  color :firebrick;
  background:lightcoral;
  padding:0.5rem;
  width: fit-content;
  letter-spacing : 0.5px;
`