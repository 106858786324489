import React, { useEffect } from 'react'
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import {
  Route,
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'
import { Navbar, Footer, SiteBreadCrumb, Page } from './components'
import { WorkflowDetails, Home, WorkflowCreationPage, WorkflowList, WFCreate, FrontPage } from './pages';



const router = createBrowserRouter(
  createRoutesFromElements(
    <>

      <Route element={<AppLayout />}  >

        <Route path="/"
          element={<Home />}>
        </Route>



        <Route path="/wfcreate"
          element={<WFCreate />}
        >
        </Route>

        <Route path="/wfcreate1"
          element={<WorkflowCreationPage />}
        >
        </Route>

        <Route path="/wflist"
          element={<WorkflowList />}
        >
        </Route>

        <Route path="/wf/:id"
          element={<WorkflowDetails />}
        >
        </Route>

      </Route>
    </>
  )
);

function AppLayout() {

  useEffect(() => {
    import("../src/Utils/multilevelCbSelector");
  }, [])

  return (
    <>
      <ReactNotifications />

      <Wrapper className="root">
        <Navbar />
        <Page />
        <Footer />
      </Wrapper>

    </>

  );
}

function App() {
  return (

    <RouterProvider router={router} />

  )
}

export default App;

const Wrapper = styled.div`  
    display : flex;
    flex-direction: column;
    width:100%;
    min-height:100vh;
    padding:0px;
    margin:0px;
`